import Image from 'next/image';
import { CSSProperties } from 'react';
import vsBackgroundImage from '@public/images/4cut-ranking/VS-background.png';
import vsTextImage from '@public/images/4cut-ranking/VS.png';

const VS = ({ style }: { style?: CSSProperties }): JSX.Element => {
  return (
    <>
      <Image
        src={vsBackgroundImage}
        alt=""
        width={139}
        height={140.5}
        style={{
          mixBlendMode: 'screen',
          zIndex: 4,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          pointerEvents: 'none',
          ...style,
        }}
      />
      <Image
        src={vsTextImage}
        alt="VS"
        width={139}
        height={140.5}
        style={{
          zIndex: 5,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          pointerEvents: 'none',
          ...style,
        }}
      />
    </>
  );
};

export default VS;
