import { useQuery } from '@tanstack/react-query';
import { HOT_REGION } from '@src/queryKeys';
import gamesApiV2 from '@src/apis/gamesApiV2';

export interface HotRegionAndCategoryRes {
  region: RankingRegionType;
  photoCardCategory: CategoryType;
}

/**
 * 실시간 핫(현재기준 24시간 가장 많은 사용자 플레이)한 지역, 카테고리 반환
 * */
const useGetHotRegion = (): HotRegionAndCategoryRes => {
  const { data } = useQuery<HotRegionAndCategoryRes>({
    queryKey: [HOT_REGION],
    queryFn: async () => {
      const res = await gamesApiV2.getHotRegion();
      return res.data;
    },
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  });

  return (
    data || {
      region: null,
      photoCardCategory: 'FEMALE',
    }
  );
};

export default useGetHotRegion;
