import axiosInstance from '@src/axios';
import { PushType } from '@features/push-history/types';
import { ResStampHistoryType, StampType } from '@src/apis/types/stamp';
import { ResCouponHistoryType, ResCouponType } from '@src/apis/types/coupon';

const getUserInfo = async (): Promise<AInstance.Res<UserInfoType>> =>
  axiosInstance.get('/api/users/me');

const getUserInfoWithToken = async (
  token: string,
): Promise<AInstance.Res<UserInfoType>> =>
  axiosInstance.get('/api/users/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const getUserOAuthInfoWithToken = async (
  token: string,
): Promise<AInstance.Res<{ profileInfos: UserOAuthInfoType }>> =>
  axiosInstance.get('/api/users/me/linked-profile', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const deleteLogout = async (): Promise<AInstance.DefaultRes> =>
  axiosInstance.delete('/api/users/logout');

const withdrawUser = async (data: {
  cause: string;
  detailCause: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.delete('/api/users/sign-out', { data });

const postProfileImage = async (
  data: FormData,
): Promise<AInstance.Res<{ profileImageUrl: string }>> =>
  axiosInstance.post('/api/users/me/profile-image', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const patchProfileInfo = async (data: {
  profileImageUrl: string;
  photograyNickname: string;
  introduction: string;
  instagramProfileUrl: string;
  tiktokProfileUrl: string;
  region?: RegionType;
}): Promise<AInstance.DefaultRes> => axiosInstance.patch('/api/users/me', data);

const patchEditPhoneNumber = async (data: {
  countryNumber: string;
  phoneNumber: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.patch('/api/users/me/phone', data);

const getMyWinCount = async (): Promise<
  AInstance.Res<{
    totalFinalWinCount: number;
    totalPickCount: number;
    prevTotalFinalWinCount: number;
    prevTotalPickCount: number;
  }>
> => axiosInstance.get('/api/users/me/total-final-win-count');

const getMyStamp = async (): Promise<AInstance.Res<StampType[]>> =>
  axiosInstance.get('/api/users/me/stamp');

const getMyUniqueCode = async (): Promise<
  AInstance.Res<{ userUniqueCode: string }>
> => axiosInstance.get('/api/users/me/unique-code');

const getOtherUserInfo = async (
  userId: string,
): Promise<AInstance.Res<ProfileInfoType>> =>
  axiosInstance.get(`/api/users/${userId}/info`);

const getUserSnsInfo = async (
  userId: string,
): Promise<AInstance.Res<{ profileInfos: UserOAuthInfoType }>> =>
  axiosInstance.get(`/api/users/${userId}/linked-profile`);

const getOtherUserWinCount = async (
  userId: string,
): Promise<
  AInstance.Res<{
    totalFinalWinCount: number;
    totalPickCount: number;
  }>
> => axiosInstance.get(`/api/users/${userId}/total-final-win-count`);

const getMyCoupon = async (): Promise<AInstance.Res<ResCouponType[]>> =>
  axiosInstance.get('/api/users/me/issued-coupon');

const patchAgreement = async (data: {
  ageAgreement: boolean;
  serviceAgreement: boolean;
  personalInfoCollectAgreement: boolean;
  emailMarketingAgreement: boolean;
  smsMarketingAgreement: boolean;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.patch('/api/users/agreements', data);

const postPhotograyNicknameDuplication = async (data: {
  photograyNickname: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.post(`/api/users/validation/photogray-nickname`, data);

const getStampHistory = async (params: {
  startDateTime: string;
  endDateTime: string;
  stampHistorySort: string;
}): Promise<AInstance.Res<ResStampHistoryType[]>> =>
  axiosInstance.get('/api/users/me/stamp-history', { params });

const getCouponHistory = async (params: {
  startDateTime: string;
  endDateTime: string;
  issuedCouponHistorySort: string;
}): Promise<AInstance.Res<ResCouponHistoryType[]>> =>
  axiosInstance.get('/api/users/me/issued-coupon-history', { params });

const getPushNotView = async (): Promise<AInstance.Res<boolean>> =>
  axiosInstance.get('/api/users/me/notification-history/not-viewed');

const getPushHistory = async (): Promise<AInstance.Res<PushType[]>> =>
  axiosInstance.get('/api/users/me/notification-history');

const patchPhotograyNickname = async (data: {
  photograyNickname: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.patch(`/api/users/me/photogray-nickname`, data);

const patchNotificationAgreement = async (data: {
  promotionNotificationAgreement: boolean;
  activityNotificationAgreement: boolean;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.patch(`/api/users/agreements/notification`, data);

const postDeviceInfo = async (
  data: MobileData,
): Promise<AInstance.DefaultRes> =>
  axiosInstance.post('/api/users/app/devices', data);

const getNewCouponVisible = async (): Promise<
  AInstance.Res<{
    popupVisible: false;
  }>
> => axiosInstance.get('/api/users/me/issued-coupon/popup-visible');

const postUsersAccess = async (data: {
  userId: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.post('/api/users/access', data);

const patchRegion = async (data: { region: string }) =>
  axiosInstance.patch('api/users/me/region', data);

export default {
  getUserInfo,
  getUserInfoWithToken,
  getUserOAuthInfoWithToken,
  deleteLogout,
  withdrawUser,
  postProfileImage,
  patchProfileInfo,
  patchEditPhoneNumber,
  getMyWinCount,
  getMyStamp,
  getMyUniqueCode,
  getOtherUserInfo,
  getUserSnsInfo,
  getOtherUserWinCount,
  getMyCoupon,
  patchAgreement,
  getStampHistory,
  getCouponHistory,
  postPhotograyNicknameDuplication,
  patchPhotograyNickname,
  patchNotificationAgreement,
  postDeviceInfo,
  getPushNotView,
  getPushHistory,
  getNewCouponVisible,
  postUsersAccess,
  patchRegion,
};
