import { useSyncExternalStore } from 'react';
import UserInfoManager from '@utils/storeManager/UserInfoManager';

const useUserInfoStore = () => {
  const store = useSyncExternalStore(
    (listener) => UserInfoManager.subscribe(listener),
    () => UserInfoManager.getUserInfo(),
    () => UserInfoManager.getUserInfo(),
  );

  const setUserData = (newValue: UserInfoType | null) =>
    UserInfoManager.setUserInfo(newValue);

  return [store, setUserData] as const;
};

export default useUserInfoStore;
