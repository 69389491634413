import Image from 'next/image';
import appIcon from '@public/images/app-icon.png';
import styled from 'styled-components';
import { getColorValue, getFontValue } from '@styles/styleUtils';
import Text from '@components/common/Text';
import Link from 'next/link';

export const Wrap = styled.div`
  ${({ theme }) => theme.layout.width};
  height: ${({ theme }) => theme.headerHeight.px};
  min-height: ${({ theme }) => theme.headerHeight.px};
  z-index: ${({ theme }) => theme.zIndex.header};
  background: ${getColorValue('white')};
  color: ${getColorValue('gray100')};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    gap: 8px;

    > span {
      word-break: keep-all;
    }

    > a.app-download-link {
      ${getFontValue('caption1_medium')};
      background-color: ${getColorValue('gray90')};
      border: 1px solid ${getColorValue('gray70')};
      color: ${getColorValue('white')};
      padding: 6px 12px;
      border-radius: 99px;
      margin-left: auto;
      white-space: nowrap;
    }
  }
`;

/**
 * 앱 설치 유도 배너, 웹으로 진입했을 경우에만 상단에 표시, 스크롤 시 위로 사라짐
 * height는 header와 동일
 * */
const WebBanner = (): JSX.Element => {
  return (
    <Wrap>
      <div>
        <Image src={appIcon} alt="PHOTOGRAY" width={36} height={36} />
        <Text typo="caption1" color="gray70">
          앱에서 더 많은 기능과 혜택을 만나보세요!
        </Text>
        <Link className="app-download-link" href="/app-download-redirect.html">
          앱에서 보기
        </Link>
      </div>
    </Wrap>
  );
};

export default WebBanner;
