export class EventEmitter {
  private listeners: Set<() => void>;

  constructor() {
    this.listeners = new Set();
  }

  // 이벤트 리스너 등록
  subscribe(listener: () => void): () => void {
    this.listeners.add(listener);

    return () => {
      this.listeners.delete(listener);
    };
  }

  // 이벤트 리스너 호출
  protected notify(): void {
    this.listeners.forEach((listener) => listener());
  }

  // 등록된 모든 리스너 제거
  clearListeners(): void {
    this.listeners.clear();
  }
}
