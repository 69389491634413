import { postAppMessage } from './appMessage';
import { CMD } from '@src/constants/appMessageCmd';

type SpecificRoutePath =
  | RoutePath
  | '/terms'
  | '/sign-up'
  | '/profile'
  | '/profile-photo-cards/';

const STATUS_BAR_DARK_COLOR_URL: SpecificRoutePath[] = [
  '/profile',
  '/home/reward',
  '/home/history/coupon',
  '/home/history/stamp',
  '/push-history',
  '/pdf-viewer',
  '/setting',
  '/notice',
  '/terms',
  '/story',
  '/login',
  '/sign-up',
  '/withdraw',
];

const LIGHT_COLOR_EXCEPTIONS = [
  '/sign-up/certificate',
  '/setting/certificate',
  '/home',
];

export function postAppStatusTextColor(url: RoutePath) {
  let statusColor: 'light' | 'dark' = 'light';

  if (LIGHT_COLOR_EXCEPTIONS.includes(url)) {
    statusColor = 'light';
  } else if (STATUS_BAR_DARK_COLOR_URL.some((u) => url.startsWith(u))) {
    statusColor = 'dark';
  }

  return postAppMessage({ cmd: CMD.UPDATE_STATUS_BAR, statusColor });
}
