import { tagManager } from '@src/tags/utils/tagManager';

export const photoCardDetailTags = {
  PhotoCardDetail_PhotoCard_Clicked: () => {
    // 포토카드상세_지역인기참가자_클릭
    tagManager('PhotoCardDetail_PhotoCard_Clicked');
  },
  PhotoCardDetail_Profile_Clicked: () => {
    // 포토카드상세_프로필보기_클릭
    tagManager('PhotoCardDetail_Profile_Clicked');
  },
  PhotoCardDetail_SNS_Clicked: (params: { snsType: string }) => {
    // 포토카드상세_SNS방문_클릭
    tagManager('PhotoCardDetail_SNS_Clicked', params);
  },
};
