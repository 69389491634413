import styled from 'styled-components';
import useGetRedDot from '@src/apis/querys/useGetRedDot';

const RedDotIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
`;

const RedDot = (): JSX.Element | null => {
  const { data } = useGetRedDot();

  return data ? <RedDotIcon /> : null;
};

export default RedDot;
