/**
 * 지역 카테고리
 * */
export const ORIGIN_REGIONS: OriginRegionType[] = [
  '서울',
  '인천 / 경기',
  '경상',
  '충청 / 강원',
  '전라 / 제주',
];

/**
 * 지역 카테고리 + "기타"
 * (지역이 등록되지 않은 값까지 포함)
 * */
export const REGIONS: RegionType[] = [
  '서울',
  '인천 / 경기',
  '경상',
  '충청 / 강원',
  '전라 / 제주',
  '기타',
];

/**
 * 지역 카테고리 + "전국(null)"
 * (게임 시에 선택 가능한 지역 카테고리 값)
 * */
export const RANKING_REGIONS: RankingRegionType[] = [
  null, // '전국'
  '서울',
  '인천 / 경기',
  '경상',
  '충청 / 강원',
  '전라 / 제주',
];

/**
 * null -> 전국 텍스트 표시에 활용
 * */
export const getRegionName = (
  value: RegionType | RankingRegionType | string,
) => {
  return value || '전국';
};
