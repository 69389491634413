import { keyframes } from 'styled-components';

export const leftBounceAnimation = keyframes`
    0% {
        transform: rotateY(35deg) translateX(0);
    }

    5% {
        transform: rotateY(35deg) translateX(2px);
    }
    
    10% {
        transform: rotateY(35deg) translateX(-1px);
    }

    15% {
        transform: rotateY(35deg) translateX(1.5px);
    }

    20% {
        transform: rotateY(35deg) translateX(-0.8px);
    }

    25% {
        transform: rotateY(35deg) translateX(1px);
    }

    30% {
        transform: rotateY(35deg) translateX(-0.5px);
    }

    35% {
        transform: rotateY(35deg) translateX(0.8px);
    }

    40% {
        transform: rotateY(35deg) translateX(-0.3px);
    }

    45% {
        transform: rotateY(35deg) translateX(0.5px);
    }
    
    50% {
        transform: rotateY(35deg) translateX(0);
    }

    55% {
        transform: rotateY(35deg) translateX(-20px);
    }

    60% {
        transform: rotateY(35deg) translateX(-16px);
    }

    65% {
        transform: rotateY(35deg) translateX(-20px);
    }

    70% {
        transform: rotateY(35deg) translateX(-17px);
    }

    75% {
        transform: rotateY(35deg) translateX(-20px);
    }

    80% {
        transform: rotateY(35deg) translateX(-18px);
    }

    85% {
        transform: rotateY(35deg) translateX(-20px);
    }

    90% {
        transform: rotateY(35deg) translateX(-19px);
    }

    95% {
        transform: rotateY(35deg) translateX(-20px);
    }
    
    100% {
        transform: rotateY(35deg) translateX(0);
    }
`;

export const rightBounceAnimation = keyframes`
    0% {
        transform: rotateY(-35deg) translateX(0);
    }

    5% {
        transform: rotateY(-35deg) translateX(-2px);
    }

    10% {
        transform: rotateY(-35deg) translateX(1px);
    }

    15% {
        transform: rotateY(-35deg) translateX(-1.5px);
    }

    20% {
        transform: rotateY(-35deg) translateX(0.8px);
    }

    25% {
        transform: rotateY(-35deg) translateX(-1px);
    }

    30% {
        transform: rotateY(-35deg) translateX(0.5px);
    }

    35% {
        transform: rotateY(-35deg) translateX(-0.8px);
    }

    40% {
        transform: rotateY(-35deg) translateX(0.3px);
    }

    45% {
        transform: rotateY(-35deg) translateX(-0.5px);
    }
    
    50% {
        transform: rotateY(-35deg) translateX(0);
    }

    55% {
        transform: rotateY(-35deg) translateX(20px);
    }

    60% {
        transform: rotateY(-35deg) translateX(16px);
    }
    
    65% {
        transform: rotateY(-35deg) translateX(20px);
    }

    70% {
        transform: rotateY(-35deg) translateX(17px);
    }

    75% {
        transform: rotateY(-35deg) translateX(20px);
    }

    80% {
        transform: rotateY(-35deg) translateX(18px);
    }

    85% {
        transform: rotateY(-35deg) translateX(20px);
    }

    90% {
        transform: rotateY(-35deg) translateX(19px);
    }

    95% {
        transform: rotateY(-35deg) translateX(20px);
    }
    
    100% {
        transform: rotateY(-35deg) translateX(0);
    }
`;
