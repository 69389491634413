import { tagManager } from '@src/tags/utils/tagManager';

export const homeTags = {
  Home_Coupon_Clicked: () => {
    // 홈_쿠폰N_클릭
    tagManager('Home_Coupon_Clicked');
  },
  Home_Stories_Clicked: () => {
    // 홈_스토리전체보기_클릭
    tagManager('Home_Stories_Clicked');
  },
  Home_Story_Clicked: (params: { title: string }) => {
    // 홈_스토리상세_클릭
    tagManager('Home_Story_Clicked', params);
  },
  Home_PhotoCards_Clicked: () => {
    // 홈_포토카드전체보기_클릭
    tagManager('Home_PhotoCards_Clicked');
  },
  Home_SpaceGame_Banner_Clicked: () => {
    // 홈_우주게임_배너_클릭
    tagManager('Home_SpaceGame_Banner_Clicked');
  },
  Region_BottomSheet_View: () => {
    // 지역정보바텀시트_조회
    tagManager('Region_BottomSheet_View');
  },
  Region_BottomSheet_Submit: () => {
    // 지역정보바텀시트_입력
    tagManager('Region_BottomSheet_Submit');
  },
  Region_BottomSheet_Exit: () => {
    // 지역정보바텀시트_이탈
    tagManager('Region_BottomSheet_Exit');
  },
  Push_BottomSheet_CouponType_View: () => {
    // 알림설정바텀시트(쿠폰강조)_조회
    tagManager('Push_BottomSheet_CouponType_View');
  },
  Push_BottomSheet_CouponType_Submit: () => {
    // 알림설정바텀시트(쿠폰강조)_동의_클릭
    tagManager('Push_BottomSheet_CouponType_Submit');
  },
  Push_BottomSheet_CouponType_Exit: () => {
    // 알림설정바텀시트(쿠폰강조)_이탈
    tagManager('Push_BottomSheet_CouponType_Exit');
  },
  Push_BottomSheet_RankingType_View: () => {
    // 알림설정바텀시트(랭킹강조)_조회
    tagManager('Push_BottomSheet_RankingType_View');
  },
  Push_BottomSheet_RankingType_Submit: () => {
    // 알림설정바텀시트(랭킹강조)_동의_클릭
    tagManager('Push_BottomSheet_RankingType_Submit');
  },
  Push_BottomSheet_RankingType_Exit: () => {
    // 알림설정바텀시트(랭킹강조)_이탈
    tagManager('Push_BottomSheet_RankingType_Exit');
  },
  ForceUpdate_Popup_View: () => {
    // 업데이트안내팝업_조회
    tagManager('ForceUpdate_Popup_View');
  },
  ForceUpdate_Popup_Clicked: () => {
    // 업데이트안내팝업_업데이트_클릭
    tagManager('ForceUpdate_Popup_Clicked');
  },
  Home_Banner_Swipe: () => {
    // 홈_배너_스와이프
    tagManager('Home_Banner_Swipe');
  },
  Home_HappyHour_Banner_View: () => {
    // 홈_해피아워_배너_조회
    tagManager('Home_HappyHour_Banner_View');
  },
  Home_HappyHour_Banner_Clicked: () => {
    // 홈_해피아워_배너_클릭
    tagManager('Home_HappyHour_Banner_Clicked');
  },
};
