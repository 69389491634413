export const agreementValidation = (userData: UserInfoType): boolean => {
  if (
    !(
      userData.ageAgreement &&
      userData.serviceAgreement &&
      userData.personalInfoCollectAgreement
    )
  ) {
    return true;
  }

  return false;
};

export const nicknameValidation = (userData: UserInfoType): boolean => {
  if (!userData.photograyNickname) {
    return true;
  }

  return false;
};

export const phoneNumberValidation = (userData: UserInfoType): boolean => {
  if (!userData.phoneNumber) {
    return true;
  }

  return false;
};
