import * as React from 'react';
import styled from 'styled-components';
import Modal from '@components/modal/Modal';
import ErrorIcon from '@icons/mobile/error-icon.svg';
import { useErrorStore } from '@src/stores/popups';
import { getColorValue, getFontValue } from '@styles/styleUtils';
import { useEffect } from 'react';

export const ERROR_MSG: Record<
  string,
  { title: string; description?: string }
> = {
  'Not allowed under age 14': {
    title: '포토그레이는 만 14세 이상부터 회원가입이 가능해요',
  },
  'User denied access': {
    title: '로그인이 취소되었어요.',
    description: '로그인 후 서비스를 이용할 수 있어요.',
  },
  'user authentication required.': {
    title: '카카오 인증 요청 안내',
    description: '카카오 계정 인증을 진행해주세요.',
  },
  'coupon not available': {
    title: '지금은 쿠폰을 사용할 수 없어요',
    description: '사용 가능 시간 : 오전 10시 ~ 오후 1시',
  },
} as const;

const ErrorModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 47px;
    height: 46px;
  }

  h1 {
    font-weight: 600;
    font-size: 18px;
    color: ${getColorValue('gray100')};
    margin-top: 16px;
  }

  p {
    ${getFontValue('body3_semiBold')};
    color: ${getColorValue('gray60')};
    margin-top: 8px;
  }
`;

const ErrorModal = (): JSX.Element => {
  const { message, setMessage } = useErrorStore();
  const [isOpen, setIsOpen] = React.useState(!!message);

  useEffect(() => {
    if (message) {
      setIsOpen(true);
    }
  }, [message]);

  const { title, description } = ERROR_MSG[message] || {
    title: '오류 발생',
    description: '알 수 없는 오류가 발생했습니다.',
  };

  const handleCancel = () => {
    setIsOpen(false);
    setTimeout(() => setMessage(''), 300);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancel}
      submitBtn={{
        label: '확인',
        onClick: handleCancel,
      }}
      content={
        <ErrorModalWrap>
          <ErrorIcon />
          <h1>{title}</h1>
          {description && <p>{description}</p>}
        </ErrorModalWrap>
      }
    />
  );
};

export default ErrorModal;
