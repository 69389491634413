import { useEffect, RefObject } from 'react';

const useOutsideClick = (
  ref: RefObject<HTMLDivElement | HTMLUListElement>,
  callback: () => void,
): void => {
  const handleClick = (e: Event) => {
    const targetDiv = e.target as HTMLDivElement;
    if (ref.current && !ref.current.contains(targetDiv)) {
      callback();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      document.addEventListener('click', handleClick);
    }, 0);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
