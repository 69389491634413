import { CSSProperties, useRef } from 'react';
import CloseIcon from '@icons/mobile/modal/close-icon.svg';
import {
  BackgroundWrap,
  ButtonWrap,
  CancelButton,
  ModalContentWrap,
  ModalHeaderWrap,
  ModalTitleWrap,
  ModalWrap,
  SubmitButton,
} from '@components/modal/styles/modal.style';
import useOutsideClick from '@hooks/useOutsideClick';
import classNames from 'classnames';
import styled from 'styled-components';
import { motion } from 'framer-motion';

interface Props {
  $style?: 'white' | 'black';
  submitBtn?: {
    label?: string;
    onClick?: () => void;
  };
  cancelBtn?: {
    label?: string;
    onClick?: () => void;
    $color?: CSSProperties['backgroundColor'];
  };
  title?: string;
  content?: React.ReactNode;
  isOpen: boolean;
  header?: React.ReactNode;
  onClose?: () => void;
  disabledOutsideClick?: boolean;
  backgroundImage?: string;
  blackBackdrop?: boolean;
  padding?: string;
}

const MotionOverlay = styled(motion.div)`
  background-color: rgba(18, 18, 20, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.modal + 1};
`;

const ModalWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  position: relative;
`;

const ModalContent = styled(motion.div)`
  background: white;
  border-radius: 12px;
  width: 100%;
  will-change: transform, opacity;
`;

const Modal = ({
  $style = 'white',
  submitBtn,
  cancelBtn,
  title,
  content,
  isOpen,
  header,
  onClose,
  disabledOutsideClick = false,
  backgroundImage,
  padding,
  blackBackdrop = false,
}: Props): JSX.Element | null => {
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => !disabledOutsideClick && onClose?.());

  if (!isOpen) return null;

  return (
    <>
      <MotionOverlay
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        onClick={onClose}
      />
      <ModalContainer>
        <ModalWrapper>
          <ModalContent
            initial={{ opacity: 0, y: 10 }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: 10,
            }}
            transition={{
              duration: 0.2,
              ease: [0.4, 0, 0.2, 1],
            }}
          >
            <BackgroundWrap $blackBackdrop={blackBackdrop}>
              <ModalWrap
                $padding={padding}
                $backgroundImage={backgroundImage}
                $style={$style}
                ref={ref}
              >
                {header && (
                  <ModalHeaderWrap>
                    <>
                      <span>{header}</span>
                      <CloseIcon onClick={onClose} />
                    </>
                  </ModalHeaderWrap>
                )}
                <ModalTitleWrap $style={$style}>{title}</ModalTitleWrap>
                {content && (
                  <ModalContentWrap
                    $style={$style}
                    className={classNames({
                      text: typeof content === 'string',
                    })}
                  >
                    {content}
                  </ModalContentWrap>
                )}
                {(cancelBtn || submitBtn) && (
                  <ButtonWrap>
                    {cancelBtn && (
                      <CancelButton
                        $color={cancelBtn.$color}
                        onClick={cancelBtn?.onClick || onClose}
                      >
                        {cancelBtn?.label || '취소'}
                      </CancelButton>
                    )}
                    {submitBtn && (
                      <SubmitButton
                        onClick={submitBtn?.onClick}
                        $style={$style}
                      >
                        {submitBtn?.label || '확인'}
                      </SubmitButton>
                    )}
                  </ButtonWrap>
                )}
              </ModalWrap>
            </BackgroundWrap>
          </ModalContent>
        </ModalWrapper>
      </ModalContainer>
    </>
  );
};

export default Modal;
