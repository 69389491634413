import { tagManager } from '@src/tags/utils/tagManager';

export const spaceGameTags = {
  SpaceGame_Tutorial_Popup_View: () => {
    // 우주게임_튜토리얼팝업
    tagManager('SpaceGame_Tutorial_Popup_View');
  },
  SpaceGame_RankingButton_Clicked: () => {
    // 우주게임_랭킹버튼_클릭
    tagManager('SpaceGame_RankingButton_Clicked');
  },
  SpaceGame_ShopButton_Clicked: () => {
    // 우주게임_상점버튼_클릭
    tagManager('SpaceGame_ShopButton_Clicked');
  },
  SpaceGame_MissionButton_Clicked: () => {
    // 우주게임_미션버튼_클릭
    tagManager('SpaceGame_MissionButton_Clicked');
  },
  SpaceGame_DrawStatusButton_Clicked: () => {
    // 우주게임_응모현황버튼_클릭
    tagManager('SpaceGame_DrawStatusButton_Clicked');
  },
  SpaceGame_Start: () => {
    // 우주게임_게임시작
    tagManager('SpaceGame_Start');
  },
  SpaceGame_GiveUp: () => {
    // 우주게임_게임중도포기
    tagManager('SpaceGame_GiveUp');
  },
  SpaceGame_End: (params: { lastStage: number }) => {
    // 우주게임_게임종료
    tagManager('SpaceGame_End', params);
  },
  SpaceGame_Applied: () => {
    // 우주게임_응모완료
    tagManager('SpaceGame_Applied');
  },
  SpaceGame_Mission_AM_Clicked: () => {
    // 우주게임_미션_오전도전권_받기
    tagManager('SpaceGame_Mission_AM_Clicked');
  },
  SpaceGame_Mission_PM_Clicked: () => {
    // 우주게임_미션_오후도전권_받기
    tagManager('SpaceGame_Mission_PM_Clicked');
  },
  SpaceGame_Mission_Ranking_Clicked: () => {
    // 우주게임_미션_포컷랭킹_받기
    tagManager('SpaceGame_Mission_Ranking_Clicked');
  },
  SpaceGame_Mission_Share_Clicked: () => {
    // 우주게임_미션_공유하기_받기
    tagManager('SpaceGame_Mission_Share_Clicked');
  },
  SpaceGame_Mission_Stamp_Clicked: () => {
    // 우주게임_미션_스탬프적립_받기
    tagManager('SpaceGame_Mission_Stamp_Clicked');
  },
};
