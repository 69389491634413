import { EventEmitter } from '@utils/storeManager/subscribeListeners';

const EVENT_NAME = 'appEventRouteChange';

class AppEventManager extends EventEmitter {
  private static instance: AppEventManager;

  private key = 'appEventRoute';

  private constructor() {
    super();

    if (typeof window !== 'undefined') {
      window.addEventListener(EVENT_NAME, this.handleStorageChange);
    }
  }

  static getInstance(): AppEventManager {
    if (!AppEventManager.instance) {
      AppEventManager.instance = new AppEventManager();
    }
    return AppEventManager.instance;
  }

  getAppEvent() {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(this.key) || '';
    }

    return '';
  }

  setAppEvent(path: string): void {
    if (typeof window !== 'undefined') {
      const currentData = localStorage.getItem(this.key);
      if (path === currentData) return;

      localStorage.setItem(this.key, path);
      window.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: '' }));

      this.notify();
    }
  }

  private handleStorageChange = (): void => {
    this.notify();
  };

  destroy(): void {
    super.clearListeners();
    window.removeEventListener(
      'AccessTokenDataChange',
      this.handleStorageChange,
    );
  }
}

export default AppEventManager.getInstance();
