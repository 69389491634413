import { tagManager } from '@src/tags/utils/tagManager';

export const winCardDetailTags = {
  WinCardDetail_SNS_Clicked: (params: { snsType: string }) => {
    // 게임완료_SNS방문_클릭
    tagManager('WinCardDetail_SNS_Clicked', params);
  },
  WinCardDetail_HotCard_Clicked: () => {
    // 게임완료_지역인기참가자_클릭
    tagManager('WinCardDetail_HotCard_Clicked');
  },
  WinCardDetail_Share_Clicked: () => {
    // 게임완료_프로필공유
    tagManager('WinCardDetail_Share_Clicked');
  },
  WinCardDetail_GamePlay_Clicked: () => {
    // 게임완료_게임다시하기
    tagManager('WinCardDetail_GamePlay_Clicked');
  },
};
