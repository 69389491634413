import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

/**
 * 홈 화면 알림허용 바텀시트
 * 수동 로그인 시 알림이 OFF 되어있는 경우에 노출, "COUPON 강조 타입" / "RANKING 강조 타입"이 번갈아가며 표시됨
 * */
export const usePushAgreePopupStore = create(
  persist<{
    isPushAgreePopupHidden: boolean;
    setPushAgreePopupHidden: (isAgree: boolean) => void;
    lastShowedPopupUiType: 'COUPON' | 'RANKING';
    setLastShowedPopupUiType: (type: 'COUPON' | 'RANKING') => void;
  }>(
    (set) => ({
      isPushAgreePopupHidden: false,
      setPushAgreePopupHidden: (isAgree: boolean) =>
        set((prev) => ({
          ...prev,
          isPushAgreePopupHidden: isAgree,
        })),
      lastShowedPopupUiType: 'RANKING',
      setLastShowedPopupUiType: (type: 'COUPON' | 'RANKING') =>
        set((prev) => ({
          ...prev,
          lastShowedPopupUiType: type,
        })),
    }),
    {
      name: 'pushAgreePopup',
    },
  ),
);

/**
 * 게임 하러가기 팝업
 * ~ 2025.01.05까지 홈 화면에 표시
 * */
export const useMoveToGamePopupStore = create(
  persist<{
    isPopupHidden: boolean;
    setIsPopupHidden: (isHidden: boolean) => void;
  }>(
    (set) => ({
      isPopupHidden: false,
      setIsPopupHidden: (isHidden: boolean) =>
        set({
          isPopupHidden: isHidden,
        }),
    }),
    {
      name: 'moveToGamePopup',
    },
  ),
);

/* 해피아워 쿠폰 홍보 팝업 */
export const usePromotionPopupStore = create(
  persist<{
    isPopupOpen: boolean;
    setIsPopupOpen: (value: boolean) => void;
  }>(
    (set) => ({
      isPopupOpen: true,
      setIsPopupOpen: (value) =>
        set({
          isPopupOpen: value,
        }),
    }),
    {
      name: 'promotionPopup',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

/**
 * MY랭킹 SNS 연결 팝업
 * 다시보지 않기 처리 시 30일동안 보지 않음
 * */
export const useSnsRemindPopupCheckedDateStore = create(
  persist<{
    pushPopupCheckedDate: string | undefined;
    setPopupCheckedDate: (value: string | undefined) => void;
  }>(
    (set) => ({
      pushPopupCheckedDate: undefined,
      setPopupCheckedDate: (value: string | undefined) =>
        set({
          pushPopupCheckedDate: value,
        }),
    }),
    {
      name: 'snsPopupRemindCheckedDate',
    },
  ),
);

/**
 * MY랭킹 -> 내 포토카드 상세 페이지 최초 진입 시 "순위"에 대한 안내 툴팁 표시용
 * */
export const useRankInfoTooltipStore = create(
  persist<{
    isDisplayedOnce: boolean;
    setDisplayedOnce: () => void;
  }>(
    (set) => ({
      isDisplayedOnce: false,
      setDisplayedOnce: () =>
        set({
          isDisplayedOnce: true,
        }),
    }),
    {
      name: 'myPhotoCardRankInfoTooltip', // localStorage
    },
  ),
);

/**
 * 포토카드 등록 완료 시 알림이 OFF 된 경우 노출
 * 다시보지 않기 처리 시 3주동안 보지 않음
 * */
export const useApplyCompletePushPopupCheckedDateStore = create(
  persist<{
    pushPopupCheckedDate: string | undefined;
    setPushPopupCheckedDate: (value: string | undefined) => void;
  }>(
    (set) => ({
      pushPopupCheckedDate: undefined,
      setPushPopupCheckedDate: (value: string | undefined) =>
        set({
          pushPopupCheckedDate: value,
        }),
    }),
    {
      name: 'applyCompletePushPopupCheckedDate',
    },
  ),
);

interface LoginPopupErrorType {
  message: string;
  setMessage: (message: string) => void;
}

export const useErrorStore = create<LoginPopupErrorType>((set) => ({
  message: '',
  setMessage: (message) =>
    set({
      message,
    }),
}));
