import * as React from 'react';
import { CSSProperties, useEffect } from 'react';
import { useAtom } from 'jotai';
import { StaticImageData } from 'next/image';
import { PageWrap } from '@styles/common-layouts';
import BottomNav from '@components/layout/BottomNav';
import MobileBottomNav from '@components/layout/MobileBottomNav';
import Header, { HeaderProps } from '@components/layout/Header';
import useDeviceStore from '@hooks/useDeviceStore';
import useScrolled from '@hooks/useScrolled';
import { layoutMountAtom } from '@src/stores/mount';
import { getColorValue, HeaderColorType } from '@styles/styleUtils';
import WebBanner from '@components/common/WebBanner';

interface Props extends HeaderProps {
  mode?: 'light' | 'dark';
  backgroundImage?: StaticImageData;
  nav?: boolean;
  children: React.ReactNode;
  customPageStyle?: CSSProperties;
  isHeaderVisible?: boolean;
}

const NavBar = ({ isApp }: { isApp: boolean }) => {
  return isApp ? <MobileBottomNav /> : <BottomNav />;
};

const PageLayout = ({
  mode = 'light',
  nav = true,
  customPageStyle,
  isHeaderVisible = true,
  ...props
}: Props) => {
  const [isScrolled] = useScrolled();
  const [isMount, setIsMount] = useAtom(layoutMountAtom);

  const [deviceInfo] = useDeviceStore();
  const isApp = !!deviceInfo?.deviceToken;
  const showWebBanner = isMount && !isApp;

  useEffect(() => {
    setIsMount(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // 모바일 바깥영역 색깔 반영
    if (document) {
      if (mode === 'light') {
        document.body.style.backgroundColor = getColorValue('white') as string;
      } else {
        document.body.style.backgroundColor = getColorValue('black') as string;
      }
    }
  }, [mode]);

  const getHeaderStyle = (): HeaderColorType => {
    if (props?.headerColor === 'transparent') {
      return isScrolled ? 'black' : 'transparent';
    }
    if (props?.headerColor) {
      return props.headerColor;
    }
    return mode === 'dark' ? 'black' : 'white';
  };

  return (
    <>
      {showWebBanner && <WebBanner />}
      <PageWrap
        mode={mode}
        $backgroundImage={props.backgroundImage}
        style={customPageStyle}
      >
        {isHeaderVisible ? (
          <Header
            pageTitle={props?.pageTitle}
            titleAlign={props?.titleAlign}
            handleClickBack={props?.handleClickBack}
            hideBackButton={props?.hideBackButton}
            headerRightButton={props?.headerRightButton || undefined}
            headerColor={getHeaderStyle()}
            showWebBanner={isMount && showWebBanner}
          />
        ) : (
          <div id="header" />
        )}
        {props.children}
        {nav && isMount && <NavBar isApp={isApp} />}
      </PageWrap>
    </>
  );
};

export default PageLayout;
