import { usePathname } from 'next/navigation';
import styled from 'styled-components';
import useAppRouter from '@hooks/useAppRouter';
import Home from '@icons/bottom-nav/web/home.svg';
import Ranking from '@icons/bottom-nav/web/ranking.svg';
import Profile from '@icons/bottom-nav/web/profile.svg';
import useUserInfoStore from '@hooks/useUserInfoStore';
import { getColorValue } from '@styles/styleUtils';

const BottomNavWrap = styled.nav`
  ${({ theme }) => theme.layout.width};
  height: ${({ theme }) => theme.webBottomNavHeight.px};
  z-index: ${({ theme }) => theme.zIndex.bottomNav};
  background: ${getColorValue('gray90')};
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const BottomNavButton = styled.button<{ $active: boolean }>`
  flex: 1 1 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  color: ${({ $active }) =>
    $active ? getColorValue('gray10') : getColorValue('gray50')};

  span {
    font-size: 11px;
  }
`;

const BottomNav: React.FC = () => {
  const router = useAppRouter();
  const pathName = usePathname();
  const [userInfo] = useUserInfoStore();

  const isPathActive = (path: string) => {
    if (path === '/') {
      return pathName === '/';
    }
    return pathName.startsWith(path);
  };

  const handleClickProfile = async () => {
    if (userInfo) {
      await router.push(`/profile/me`);
    } else {
      await router.replace('/login');
    }
  };

  return (
    <BottomNavWrap id="bottom-nav">
      <BottomNavButton
        onClick={() => router.push('/')}
        $active={isPathActive('/')}
      >
        <Home />
        <span>홈</span>
      </BottomNavButton>
      <BottomNavButton
        onClick={() => router.push('/ranking')}
        $active={isPathActive('/ranking')}
      >
        <Ranking />
        <span>랭킹</span>
      </BottomNavButton>
      <BottomNavButton
        onClick={handleClickProfile}
        $active={isPathActive('/profile')}
      >
        <Profile />
        <span>MY랭킹</span>
      </BottomNavButton>
    </BottomNavWrap>
  );
};

export default BottomNav;
