import { tagManager } from '@src/tags/utils/tagManager';

export const promotionTags = {
  HappyHour_Download_Clicked: () => {
    // 해피아워_쿠폰_다운로드_클릭
    tagManager('HappyHour_Download_Clicked');
  },
  HappyHour_NotAvailable: () => {
    // 해피아워_쿠폰소진완료_조회
    tagManager('HappyHour_NotAvailable');
  },
  HappyHour_UseCoupon_Clicked: () => {
    // 해피아워_쿠폰사용하기_클릭
    tagManager('HappyHour_UseCoupon_Clicked');
  },
  HappyHour_CouponList_Clicked: () => {
    // 해피아워_쿠폰함바로가기_클릭
    tagManager('HappyHour_CouponList_Clicked');
  },
  HappyHour_BackButton_Clicked: () => {
    // 해피아워_뒤로가기_클릭
    tagManager('HappyHour_BackButton_Clicked');
  },
  HappyHour_Popup_View: () => {
    // 해피아워_팝업_조회
    tagManager('HappyHour_Popup_View');
  },
  HappyHour_Popup_Clicked: () => {
    // 해피아워_팝업_쿠폰받기_클릭
    tagManager('HappyHour_Popup_Clicked');
  },
  HappyHour_Popup_Exit_Clicked: () => {
    // 해피아워_팝업_닫기_클릭
    tagManager('HappyHour_Popup_Exit_Clicked');
  },
};
