import { DateTime, ToRelativeUnit } from 'luxon';

/* yyyy.MM.dd */
export const convertJSDateToSeoulDate = (value: Date): string =>
  DateTime.fromISO(value.toISOString(), { zone: 'Asia/Seoul' }).toFormat(
    'yyyy.MM.dd',
  );

/* yyyy.MM.dd */
export const convertISOStringToSeoulDate = (value: string): string =>
  DateTime.fromISO(value, { zone: 'Asia/Seoul' }).toFormat('yyyy.MM.dd');

/* yyyy.MM.dd HH:mm:ss */
export const convertISOStringToSeoulDateTime = (value: string): string =>
  DateTime.fromISO(value, { zone: 'Asia/Seoul' }).toFormat(
    'yyyy.MM.dd HH:mm:ss',
  );

/* yyyy년 MM월 dd일 HH:mm */
export const convertISOStringToSeoulLongDateTime = (value: string): string =>
  DateTime.fromISO(value, { zone: 'Asia/Seoul' }).toFormat(
    'yyyy년 MM월 dd일 HH:mm',
  );

/* M월 d일 */
export const convertDateToKoreanMonthAndDay = (value: Date | string): string =>
  DateTime.fromISO(typeof value === 'string' ? value : value.toISOString(), {
    zone: 'Asia/Seoul',
  }).toFormat('M월 d일');

/* M월 d일 00:00 */
export const convertDateToKoreanMonthAndDayAndTime = (
  value?: Date | string,
): string => {
  if (!value) {
    return '-월 -일 00:00';
  }
  return DateTime.fromISO(
    typeof value === 'string' ? value : value.toISOString(),
    {
      zone: 'Asia/Seoul',
    },
  ).toFormat('M월 d일 HH:mm');
};

/* MM.dd */
export const convertJSDateToSeoulMonthDay = (value: string): string =>
  DateTime.fromISO(value, { zone: 'Asia/Seoul' }).toFormat('MM.dd');

/* HH:mm */
export const convertJSDateToSeoulTime = (value: string): string =>
  DateTime.fromISO(value, { zone: 'Asia/Seoul' }).toFormat('HH:mm');

/* n달 만큼 빼기 */
export const minusMonthSeoulTime = (month: number): string =>
  DateTime.now()
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .minus({ month })
    .toUTC()
    .toISO({ suppressMilliseconds: true }) as string;

/* 오늘 날짜 */
export const todaySeoulTime = (): string =>
  DateTime.now()
    .set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    })
    .toUTC()
    .toISO({ suppressMilliseconds: true }) as string;

/* js Date to DateTime */
export const DateToLuxon = (date: Date, type: string): string => {
  return type === 'start'
    ? (DateTime.fromJSDate(date)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toUTC()
        .toISO({ suppressMilliseconds: true }) as string)
    : (DateTime.fromJSDate(date)
        .set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        })
        .toUTC()
        .toISO({ suppressMilliseconds: true }) as string);
};

/** '방금 전', '1분 전', '23시간 전', '10일 전', '22.01.31'  */
export const getRelativeOrAbsoluteDate = (value: string): string => {
  const date = DateTime.fromISO(value, { zone: 'utc', locale: 'ko' });
  const now = DateTime.now().setZone('utc').setLocale('ko');
  const durationFromNow = date.diff(now, ['seconds', 'days']);

  if (durationFromNow.days < -10) {
    return date.toFormat('yy.MM.dd');
  }

  if (
    durationFromNow.days === 0 &&
    durationFromNow.seconds <= 0 &&
    durationFromNow.seconds > -60
  ) {
    return '방금 전';
  }

  const units: ToRelativeUnit[] = ['days', 'hours', 'minutes'];

  return (
    date.toRelative({
      unit: units,
      locale: 'ko',
    }) ?? 'invalid'
  );
};

/** 3개월 경과 여부  */
export const isOverThreeMonths = (value: string): boolean => {
  const inputDate = DateTime.fromISO(value);
  const threeMonthsAgo = DateTime.now().minus({ months: 3 });

  return inputDate < threeMonthsAgo;
};

/**
 * year, monthIndex로 해당 월 첫날의 자정값 반환
 * (2024, 10) => "2024-10-31T15:00:00Z"
 * */
export const getFirstDayISOStringOfMonth = (
  year: number,
  monthIndex: number,
): string => {
  if (monthIndex > 11) {
    return DateTime.utc(year + 1, monthIndex - 11, 1)
      .setZone('Asia/Seoul')
      .startOf('day')
      .toUTC()
      .toISO({ suppressMilliseconds: true }) as string;
  }

  return DateTime.utc(year, monthIndex + 1, 1)
    .setZone('Asia/Seoul')
    .startOf('day')
    .toUTC()
    .toISO({ suppressMilliseconds: true }) as string;
};
