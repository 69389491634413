import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { RANKING_GAME_PLAYED_COUNT } from '@src/queryKeys';
import gamesApiV2 from '@src/apis/gamesApiV2';

/**
 * 포컷랭킹 게임 생성 수 반환
 * */
const useGetGamePlayedCount = (): UseQueryResult<{ count: number }> => {
  return useQuery<{
    count: number;
  }>({
    queryKey: [RANKING_GAME_PLAYED_COUNT],
    queryFn: async () => {
      const res = await gamesApiV2.getGamePlayedCount();
      return res.data;
    },
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  });
};

export default useGetGamePlayedCount;
