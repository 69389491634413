import React from 'react';
import { toast } from 'react-toastify';

interface ToastProps {
  type?: 'success' | 'error';
  content?: string | JSX.Element;
}

export const showSimpleToast = ({
  type = 'success',
  content,
}: ToastProps): void => {
  const getToastContent = () => (
    <div className="toast-content">{content || ''}</div>
  );

  toast(getToastContent(), {
    type,
    position: 'bottom-center',
    autoClose: 2000,
    hideProgressBar: true,
    pauseOnHover: true,
  });
};
