import { tagManager } from '@src/tags/utils/tagManager';

export const myRankingTags = {
  MyRanking_Profile_Edit_Clicked: () => {
    // MY랭킹_프로필수정_클릭
    tagManager('MyRanking_Profile_Edit_Clicked');
  },
  MyRanking_SNS_Popup_Clicked: () => {
    // MY랭킹_SNS팝업_연결_클릭
    tagManager('MyRanking_SNS_Popup_Clicked');
  },
  MyRanking_SNS_Popup_Hide_Clicked: () => {
    // MY랭킹_SNS팝업_다시보지않기_클릭
    tagManager('MyRanking_SNS_Popup_Hide_Clicked');
  },
  MyRanking_ApplyButton_Clicked: () => {
    // MY랭킹_포컷랭킹참가하기_클릭
    tagManager('MyRanking_ApplyButton_Clicked');
  },
};
