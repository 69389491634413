import { useSyncExternalStore } from 'react';
import AccessTokenManager from '@utils/storeManager/AccessTokenManager';

const useAccessToken = () => {
  const store = useSyncExternalStore(
    (listener) => AccessTokenManager.subscribe(listener),
    () => AccessTokenManager.getAccessToken(),
    () => AccessTokenManager.getAccessToken(),
  );

  const setAccessToken = (newValue: string) =>
    AccessTokenManager.setAccessToken(newValue);

  return [store || '', setAccessToken] as const;
};

export default useAccessToken;
