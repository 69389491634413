import { pageViewTags } from '@src/tags/pageViewTags';
import { signInAndUpTags } from '@src/tags/signInAndUpTags';
import { homeTags } from '@src/tags/homeTags';
import { couponTags } from '@src/tags/couponTags';
import { qrTags } from '@src/tags/qrTags';
import { rankingMainTags } from '@src/tags/rankingMainTags';
import { realTimeRankingTags } from '@src/tags/realTimeRankingTags';
import { hallOfFameTags } from '@src/tags/hallOfFameTags';
import { photoCardDetailTags } from '@src/tags/photoCardDetailTags';
import { applyTags } from '@src/tags/applyTags';
import { gameTags } from '@src/tags/gameTags';
import { winCardDetailTags } from '@src/tags/winCardDetailTags';
import { myRankingTags } from '@src/tags/myRankingTags';
import { profileEditTags } from '@src/tags/profileEditTags';
import { profileTags } from '@src/tags/profileTags';
import { settingTags } from '@src/tags/settingTags';
import { spaceGameTags } from '@src/tags/spaceGameTags';
import { promotionTags } from '@src/tags/promotionTags';

export const googleTagManager = {
  ...pageViewTags,
  ...signInAndUpTags,
  ...homeTags,
  ...couponTags,
  ...qrTags,
  ...rankingMainTags,
  ...realTimeRankingTags,
  ...hallOfFameTags,
  ...photoCardDetailTags,
  ...applyTags,
  ...gameTags,
  ...winCardDetailTags,
  ...myRankingTags,
  ...profileEditTags,
  ...profileTags,
  ...settingTags,
  ...spaceGameTags,
  ...promotionTags,
};
