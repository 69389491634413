export const HOT_REGION = 'HOT_REGION';
export const REGION_TOP_TWO_CARDS = 'REGION_TOP_TWO_CARDS';
export const RANKING_GAME_PLAYED_COUNT = 'GAME_PLAYED_COUNT';

export const REAL_TIME_RANKING = 'REAL_TIME_RANKING';
export const HALL_OF_FAME = 'HALL_OF_FAME';

export const PHOTO_CARD_DETAIL = 'PHOTO_CARD_DETAIL';
export const RECENT_WIN_CARD_LIST = 'RECENT_WIN_CARD_LIST';
export const CATEGORY_PHOTO_CARD_LIST = 'CATEGORY_PHOTO_CARD_LIST';
export const PHOTO_CARD_HOT_RANDOM = 'PHOTO_CARD_HOT_RANDOM';

export const MY_PHOTO_CARD_LIST = 'MY_PHOTO_CARD_LIST';
export const MY_WIN_COUNT = 'MY_WIN_COUNT';

export const PROFILE_INFO = 'PROFILE_INFO';
export const PROFILE_SNS_INFO = 'PROFILE_SNS_INFO';
export const PROFILE_PHOTO_CARD_LIST = 'PROFILE_PHOTO_CARD_LIST';
export const PROFILE_WIN_COUNT = 'PROFILE_WIN_COUNT';

export const COUPON_EXCLUDED_BRANCH_LIST = 'COUPON_EXCLUDED_BRANCH_LIST';

export const STORIES_LIST = 'STORIES_LIST';
export const STORY_DETAIL = 'STORY_DETAIL';

export const QR = 'QR';

export const HAS_NEW_COUPON = 'HAS_NEW_COUPON';
export const COUPON = 'COUPON';
export const COUPON_HISTORY = 'COUPON_HISTORY';
export const COUPON_PROMOTION = 'COUPON_PROMOTION';
export const STAMP = 'STAMP';
export const STAMP_HISTORY = 'STAMP_HISTORY';

export const PUSH_HISTORY = 'PUSH_HISTORY';
export const RED_DOT = 'RED_DOT';

export const NOTICE = 'NOTICE';
export const NOTICE_DETAIL = 'NOTICE_DETAIL';

export const SPACE_GAME_SEASON = 'SPACE_GAME_SEASON';
export const SPACE_GAME_USER_INFO = 'SPACE_GAME_USER_INFO';
export const SPACE_GAME_RANKING = 'SPACE_GAME_RANKING';
export const SPACE_GAME_APPLIED_COUNT = 'SPACE_GAME_APPLIED_COUNT';
export const SPACE_GAME_GIFT_WIN_CHECK = 'SPACE_GAME_GIFT_WIN_CHECK';
export const SPACE_GAME_MISSION_INFO = 'SPACE_GAME_MISSION_INFO';
export const SPACE_GAME_PREV_SEASON_WIN_INFO =
  'SPACE_GAME_PREV_SEASON_WIN_INFO';
