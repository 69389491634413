import { tagManager } from '@src/tags/utils/tagManager';

export const signInAndUpTags = {
  SignIn_SNS_SignedIn: () => {
    // 로그인
    tagManager('SignIn_SNS_SignedIn');
  },
  SignIn_SNS_SignedIn_Web: () => {
    // 로그인(웹)
    tagManager('SignIn_SNS_SignedIn_Web');
  },
  Login_Apple: () => {
    // 로그인_애플
    tagManager('Login_Apple');
  },
  Login_Kakao: () => {
    // 로그인_카카오톡
    tagManager('Login_Kakao');
  },
  SignUp_Membership_Completed: () => {
    // 회원가입완료
    tagManager('SignUp_Membership_Completed');
  },
  SignUp_Membership_Completed_Web: () => {
    // 회원가입완료(웹)
    tagManager('SignUp_Membership_Completed_Web');
  },
  Signup_Certificate_Start: () => {
    // 회원가입_본인인증시작
    tagManager('Signup_Certificate_Start');
  },
  Signup_Certificate_End: () => {
    // 회원가입_본인인증진행
    tagManager('Signup_Certificate_End');
  },
  Signup_Region_Submit: () => {
    // 회원가입_지역정보_입력
    tagManager('Signup_Region_Submit');
  },
  Signup_PushAgree_Submit: () => {
    // 회원가입_알림동의_동의_클릭
    tagManager('Signup_PushAgree_Submit');
  },
  Signup_PushAgree_Exit: () => {
    // 회원가입_알림동의_이탈
    tagManager('Signup_PushAgree_Exit');
  },
};
