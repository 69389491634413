import styled, { css } from 'styled-components';
import { CSSProperties } from 'react';
import { getColorValue, getFontValue } from '@styles/styleUtils';

export const CouponWrap = styled.div<{ $style?: CSSProperties }>`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100px;
  ${({ $style }) => ($style ? { ...$style } : {})};
`;

export const CouponSvgWrap = styled.div<{
  $boxShadow?: string;
  $isBlur?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ $isBlur }) =>
    $isBlur
      ? css`
          backdrop-filter: blur(2px);
          z-index: 2;
        `
      : ''};
  filter: ${({ $boxShadow }) =>
    $boxShadow ? `drop-shadow(${$boxShadow})` : 'none'};
`;

export const DimmedContentWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const CouponContentWrap = styled.div<{
  $rightWidth?: number;
  direction?: 'horizontal' | 'vertical';
}>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  ${({ direction, $rightWidth = 0 }) =>
    direction === 'horizontal' &&
    css`
      > .left-side {
        flex-grow: 1;
      }

      > .right-side {
        width: ${$rightWidth ? $rightWidth + 'px' : 'fit-content'};
        min-width: ${$rightWidth ? $rightWidth + 'px' : 'fit-content'};
        max-width: ${$rightWidth ? $rightWidth + 'px' : 'fit-content'};
        height: 100%;
      }
    `}

  ${({ direction }) =>
    direction === 'vertical' &&
    css`
      flex-direction: column;

      > .top-side,
      > .bottom-side {
        width: 100%;
      }
    `}
`;

export const LeftSideWrap = styled.div`
  padding: 20px;

  .ticket-title {
    display: flex;
    gap: 4px;
    align-items: flex-start;

    .d-day {
      ${getFontValue('caption2_semiBold')};
      padding: 2px 8px;
      margin-top: 4px;
      border-radius: 99px;
      color: ${getColorValue('white')};
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${getColorValue('red')};
      white-space: nowrap;
    }
  }
`;

export const RightSideWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ExcludedBranchButtonWrap = styled.button`
  border: 1px solid ${getColorValue('gray20')};
  background-color: ${getColorValue('gray10')};
  padding: 6px 8px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 99px;
  color: ${getColorValue('gray60')};
  ${getFontValue('caption2')};

  &::after {
    content: '';
    background-image: url('/icons/mobile/right-arrow.svg');
    display: inline-block;
    width: 14px;
    height: 14px;
    background-size: 100% 100%;
    margin-left: 4px;
  }
`;
