import { ReactNode } from 'react';

interface ModalItem {
  id: number;
  component: ReactNode;
  resolve: (value: boolean) => void;
  reject: (reason?: unknown) => void;
  isClosing?: boolean;
}

type Listener = (modals: ModalItem[]) => void;

type ModalRenderer = (close: (result?: boolean) => void) => ReactNode;

export type ModalOpenReturn = {
  id: number;
  promise: Promise<boolean>;
};

class ModalManager {
  private static instance: ModalManager;
  private observers: Set<Listener> = new Set();
  private modals: ModalItem[] = [];
  private currentId: number = 0;

  private constructor() {}

  static getInstance(): ModalManager {
    if (!ModalManager.instance) {
      ModalManager.instance = new ModalManager();
    }
    return ModalManager.instance;
  }

  subscribe(listener: Listener): () => void {
    this.observers.add(listener);
    return () => {
      this.observers.delete(listener);
    };
  }

  open(renderer: ModalRenderer): ModalOpenReturn {
    const id = ++this.currentId;

    const promise = new Promise<boolean>((resolve, reject) => {
      const close = (result: boolean = true) => {
        const modalIndex = this.modals.findIndex(modal => modal.id === id);
        if (modalIndex !== -1) {
          this.modals[modalIndex].isClosing = true;
          this.notify();
          
          // Wait for animation to complete
          setTimeout(() => {
            this.modals = this.modals.filter(modal => modal.id !== id);
            this.notify();
            resolve(result);
          }, 300); // Slightly longer than animation duration
        }
      };

      this.modals.push({
        id,
        component: renderer(close),
        resolve,
        reject,
        isClosing: false
      });
      this.notify();
    });

    return { id, promise };
  }

  private notify() {
    this.observers.forEach(observer => observer([...this.modals]));
  }
}

export const modalManager = ModalManager.getInstance();
