import { useSyncExternalStore } from 'react';
import DeviceDataManager from '@utils/storeManager/DeviceInfoManager';

const useDeviceStore = () => {
  const store = useSyncExternalStore(
    (listener) => DeviceDataManager.subscribe(listener),
    () => DeviceDataManager.getDeviceData(),
    () => DeviceDataManager.getDeviceData(),
  );

  const setDeviceData = (newValue: MobileDataState) =>
    DeviceDataManager.setDeviceData(newValue);

  return [store, setDeviceData] as const;
};

export default useDeviceStore;
