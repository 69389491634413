import { tagManager } from '@src/tags/utils/tagManager';

export const rankingMainTags = {
  Ranking_Apply_Button_Clicked: () => {
    // 게임_참가하기_클릭
    tagManager('Ranking_Apply_Button_Clicked');
  },
  Ranking_Apply_Button_Clicked_Web: () => {
    // 게임_참가하기_클릭(웹)
    tagManager('Ranking_Apply_Button_Clicked_Web');
  },
};
