import { tagManager } from '@src/tags/utils/tagManager';

export const hallOfFameTags = {
  HallOfFame_PhotoCard_Clicked: () => {
    // 명예의전당_포토카드_클릭
    tagManager('HallOfFame_PhotoCard_Clicked');
  },
  HallOfFame_ApplyBanner_Clicked: () => {
    // 명예의전당_참가배너_클릭
    tagManager('HallOfFame_ApplyBanner_Clicked');
  },
  HallOfFame_GamePlay_Clicked: () => {
    // 명예의전당_게임하기_클릭
    tagManager('HallOfFame_GamePlay_Clicked');
  },
};
