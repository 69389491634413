import { useEffect, useState } from 'react';

/**
 * 세로 스크롤 되지 않은 경우 false, scrollY가 0을 초과하는 경우 true
 * */
const useScrolled = (): [boolean] => {
  const [isScrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const { scrollY } = window;

    if (scrollY <= 0) {
      return setScrolled(false);
    }
    return setScrolled(true);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll, {
        passive: true,
        capture: true,
      });
    }
    handleScroll(); // 최초실행용
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  return [isScrolled];
};

export default useScrolled;
