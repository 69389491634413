import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css';

/* stylelint-disable */
export const GlobalStyles = createGlobalStyle`
  ${reset};
  
  :root {
      --safe-area-inset-top: 0px;
      --safe-area-inset-bottom: 0px;
  }
  
  *, *::before, *::after,
  html, body, #__next {
    font-family: 'Pretendard',
      /* macOS 10.11-10.12 */ -apple-system,
      /* Windows 6+ */ "Segoe UI",
      /* Android 4+ */ "Roboto",
      /* Ubuntu 10.10+ */ "Ubuntu",
      /* Gnome 3+ */ "Cantarell",
      /* KDE Plasma 5+ */ "Noto Sans",
      /* fallback */ sans-serif,
      /* macOS emoji */ "Apple Color Emoji",
      /* Windows emoji */ "Segoe UI Emoji",
      /* Windows emoji */ "Segoe UI Symbol",
      /* Linux emoji */ "Noto Color Emoji",
    sans-serif;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
    }
  }
  
  body {
    -ms-user-select: none; 
    -moz-user-select: none;
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    user-select:none;
  }

  html, body,
  #__next {
    height: 100%;
    overscroll-behavior: none;
  }
  
  button {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  p {
    padding: 0;
    margin: 0;
  }
`;
