import { tagManager } from '@src/tags/utils/tagManager';

export const couponTags = {
  Coupon_Branch_Clicked: () => {
    // 쿠폰_사용불가매장_클릭
    tagManager('Coupon_Branch_Clicked');
  },
  Coupon_Barcode_Clicked: () => {
    // 쿠폰_바코드_조회
    tagManager('Coupon_Barcode_Clicked');
  },
  Coupon_Barcode_Zoom: () => {
    // 쿠폰_바코드_크게보기
    tagManager('Coupon_Barcode_Zoom');
  },
  Coupon_HappyHour_Barcode_Clicked: () => {
    // 쿠폰_해피아워쿠폰_바코드_조회
    tagManager('Coupon_HappyHour_Barcode_Clicked');
  },
};
