import { tagManager } from '@src/tags/utils/tagManager';

export const gameTags = {
  Game_SelectCategory: (params: { category: string }) => {
    // 게임-게임시작
    tagManager('Game_SelectCategory', params);
  },
  Game_SelectCategory_Web: () => {
    // 게임-게임시작(웹)
    tagManager('Game_SelectCategory_Web');
  },
  Game_Enter_Tournament: (params: { category: string; memberType: string }) => {
    // 게임-16강전_진입
    tagManager('Game_Enter_Tournament', params);
  },
  Game_Enter_QuarterFinal: (params: {
    category: string;
    memberType: string;
  }) => {
    // 게임-8강전_진입
    tagManager('Game_Enter_QuarterFinal', params);
  },
  Game_Enter_SemiFinal: (params: { category: string; memberType: string }) => {
    // 게임-준결승전_진입
    tagManager('Game_Enter_SemiFinal', params);
  },
  Game_Enter_Final: (params: { category: string; memberType: string }) => {
    // 게임-결승전_진입
    tagManager('Game_Enter_Final', params);
  },
  Game_Pick_Tournament: (params: {
    category: string;
    memberType: string;
    picked: string;
    round: number;
  }) => {
    // 게임-16강전_선택
    tagManager('Game_Pick_Tournament', params);
  },
  Game_Pick_QuarterFinal: (params: {
    category: string;
    memberType: string;
    picked: string;
    round: number;
  }) => {
    // 게임-8강전_선택
    tagManager('Game_Pick_QuarterFinal', params);
  },
  Game_Pick_SemiFinal: (params: {
    category: string;
    memberType: string;
    picked: string;
    round: number;
  }) => {
    // 게임-준결승전_선택
    tagManager('Game_Pick_SemiFinal', params);
  },
  Game_Pick_Final: (params: {
    category: string;
    memberType: string;
    picked: string;
  }) => {
    // 게임-결승전_최종선택
    tagManager('Game_Pick_Final', params);
  },
  Game_Finished: (params: { category: string; photoCardName: string }) => {
    // 게임-게임완료
    tagManager('Game_Finished', params);
  },
  Game_Finished_Web: () => {
    // 게임-게임완료(웹)
    tagManager('Game_Finished_Web');
  },
};
