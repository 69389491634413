import { modalManager } from '@src/utils/manager/modalManager';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';

interface ModalItem {
  id: number;
  component: React.ReactNode;
  isClosing?: boolean;
}

export function ModalPortal() {
  const [modals, setModals] = useState<ModalItem[]>([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    const unsubscribe = modalManager.subscribe((modalItems) => {
      setModals(modalItems);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (!mounted) return null;

  const modalRoot = document.getElementById('modal-root') || document.body;

  return createPortal(
    <AnimatePresence mode="wait">
      {modals.map((modal) => (
        <div key={modal.id}>{modal.component}</div>
      ))}
    </AnimatePresence>,
    modalRoot,
  );
}
