import { create } from 'zustand';

export type PushStatus = 'disabled' | 'enabled';

interface PushStatusStore {
  pushStatus: PushStatus;
  setPushStatus: (status: PushStatus) => void;
}

export const usePushStatusStore = create<PushStatusStore>((set) => ({
  pushStatus: 'disabled',
  setPushStatus: (status: PushStatus) => set({ pushStatus: status }),
}));
