import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import React, { ReactElement, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { useAtom } from 'jotai';
import { pathAtom } from '@src/stores/path';
import { GlobalStyles } from '@styles/global-styles';
import { defaultTheme } from '@styles/theme';
import '@styles/reactToastify.css';
import '@styles/reactDateRange.css';
import '@public/fonts/font.css';
import '@styles/typoStyle.css';
import DEFAULT_SEO from '@src/seo';
import GlobalModals from '@components/layout/GlobalModals';
import { GlobalPortal } from '@components/portal/GlobalPortal';
import useLoginAuth from '@hooks/useLoginAuth';
import { makeHandlers } from '@utils/WebAppConnection';
import { devHandlers } from '@utils/devHandeler';
import { themeV2 } from '@styles/theme_v2';
import ReactQueryProviders from '@src/libs/react-query/ReactQueryProviders';
import useDeviceStore from '@hooks/useDeviceStore';
import { googleTagManager } from '@src/tags';
import useForcedUpdate from '@hooks/useForcedUpdate';
import { ModalPortal } from '@src/components/modal/ModalPortal';

makeHandlers();
devHandlers();

const tagManagerArgs = {
  gtmId: 'GTM-5LCKGHG',
};

const MyApp = ({ Component, pageProps }: AppProps): ReactElement => {
  const router = useRouter();
  const [path, setPath] = useAtom(pathAtom);
  useLoginAuth();
  const UpdateModal = useForcedUpdate('2.1.0');

  function kakaoInit() {
    // 페이지가 로드되면 실행
    window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_SDK);
  }

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  const [deviceInfo] = useDeviceStore();

  useEffect(() => {
    if (!deviceInfo?.paddingTop || !deviceInfo.paddingBottom) return;
    document.documentElement.style.setProperty(
      '--safe-area-inset-top',
      `${deviceInfo.paddingTop}px`,
    );
    document.documentElement.style.setProperty(
      '--safe-area-inset-bottom',
      `${deviceInfo.paddingBottom}px`,
    );
    // return () => unsubscribe();
  }, [deviceInfo?.paddingTop, deviceInfo?.paddingBottom]);

  useEffect(() => {
    // 로그인 로딩 화면('/login/complete')은 pathAtom 에 쌓이지 않도록 처리
    const loginRedirectUriPath = '/login/complete';
    if (
      path.currentPath !== router.asPath &&
      !router.asPath.includes(loginRedirectUriPath)
    ) {
      setPath((prev) => ({
        prevPath: prev.currentPath,
        currentPath: router.asPath,
      }));
    }

    googleTagManager.Page_View(router.asPath as RoutePath);
  }, [router.asPath]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GlobalPortal.Provider>
      <ReactQueryProviders>
        <GlobalStyles />
        <ThemeProvider theme={{ ...defaultTheme, ...themeV2 }}>
          <ToastContainer />
          <DefaultSeo {...DEFAULT_SEO} />
          <>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
              />
            </Head>
            <ModalPortal />
            <Component {...pageProps} />
            <UpdateModal />
            <GlobalModals />
            <Script
              src="https://t1.kakaocdn.net/kakao_js_sdk/2.7.0/kakao.min.js"
              onLoad={() => kakaoInit()}
              integrity="sha384-l+xbElFSnPZ2rOaPrU//2FF5B4LB8FiX5q4fXYTlfcG4PGpMkE1vcL7kNXI6Cci0"
              crossOrigin="anonymous"
            />
            {process.env.NEXT_PUBLIC_APP_ENV !== 'production' && (
              <Script
                src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
                onLoad={() => new window.VConsole()}
              />
            )}
          </>
        </ThemeProvider>
      </ReactQueryProviders>
    </GlobalPortal.Provider>
  );
};

export default appWithTranslation(MyApp);
