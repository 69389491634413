import { CouponSvgWrap } from '@components/common/styles/coupon.style';
import React from 'react';

interface Props {
  uniqueKey: string; // svg mask id 지정용
  $borderColor?: string; // 테두리 색상
  $dashColor?: string; // 점선 색상
  $fillColor?: string; // 배경 색상
  $boxShadow?: string; // 그림자
  $isBlur?: boolean;
  svgWidth: number;
  svgHeight: number;
  topContentHeight: number;
}

/**
 * 쿠폰의 영역이 위아래로 나뉘어있는 쿠폰 UI
 * */
const VerticalCouponSvg = ({
  uniqueKey,
  $boxShadow,
  $fillColor,
  $borderColor,
  $dashColor,
  $isBlur,
  svgWidth,
  svgHeight,
  topContentHeight,
}: Props) => {
  return (
    <CouponSvgWrap $boxShadow={$boxShadow} $isBlur={$isBlur}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        width={svgWidth}
        height={svgHeight}
      >
        {topContentHeight && (
          <>
            {/* svg mask */}
            <mask id={`ticket-mask-vertical-${uniqueKey}`}>
              <rect
                x="0"
                y="0"
                width={svgWidth}
                height={svgHeight}
                rx="15"
                ry="15"
                fill="white"
              />
              <circle cx={0} cy={topContentHeight} r="8" fill="black" />
              <circle cx={svgWidth} cy={topContentHeight} r="8" fill="black" />
            </mask>
          </>
        )}

        {/* 직사각형 */}
        <rect
          x="0"
          y="0"
          width={svgWidth}
          height={svgHeight}
          rx="15"
          ry="15"
          fill={$fillColor}
          stroke={$borderColor}
          strokeWidth="2"
          mask={`url(#ticket-mask-vertical-${uniqueKey})`}
        />
        {topContentHeight && (
          <>
            {/* 테두리용 반원 - 왼쪽 */}
            <circle
              cx={0}
              cy={topContentHeight}
              r="9"
              fill={$borderColor}
              mask={`url(#ticket-mask-vertical-${uniqueKey})`}
            />
            {/* 테두리용 반원 - 오른쪽 */}
            <circle
              cx={svgWidth}
              cy={topContentHeight}
              r="9"
              fill={$borderColor}
              mask={`url(#ticket-mask-vertical-${uniqueKey})`}
            />
          </>
        )}

        {/* 가로 점선 */}
        <path
          d={`M32 ${topContentHeight} L${svgWidth - 32} ${topContentHeight}`}
          stroke={$dashColor}
          strokeWidth="1.5"
          strokeDasharray="4 8"
        />
      </svg>
    </CouponSvgWrap>
  );
};

export default VerticalCouponSvg;
