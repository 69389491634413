import { useQuery } from '@tanstack/react-query';
import { REGION_TOP_TWO_CARDS } from '@src/queryKeys';
import gamesApiV2 from '@src/apis/gamesApiV2';
import useGetHotRegion from '@src/apis/querys/region-queries/useGetHotRegion';

export interface TwoCardsRes {
  firstPhotoCard: PhotoCardType | undefined;
  secondPhotoCard: PhotoCardType | undefined;
}

/**
 * 실시간 핫한 지역, 카테고리의 1위, 2위 포토카드 반환
 * */
const useGetHotRegionTopTwoCards = (): TwoCardsRes => {
  const { region, photoCardCategory } = useGetHotRegion();

  const { data } = useQuery<{
    firstPhotoCard: PhotoCardType;
    secondPhotoCard: PhotoCardType;
  }>({
    queryKey: [REGION_TOP_TWO_CARDS, region, photoCardCategory],
    queryFn: async () => {
      const res = await gamesApiV2.getRegionTopTwoCards({
        region,
        photoCardCategory,
      });
      return res.data;
    },
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  });

  return (
    data || {
      firstPhotoCard: undefined,
      secondPhotoCard: undefined,
    }
  );
};

export default useGetHotRegionTopTwoCards;
