import usersApi from '@src/apis/usersApi';
import pushApi from '@src/apis/pushApi';

import { OnResumeHandler } from '@utils/space-game/resume-handler';
import { PushStatus, usePushStatusStore } from '@src/stores/push-notification';
import AccessTokenManager from '@utils/storeManager/AccessTokenManager';
import RefreshTokenManager from '@utils/storeManager/RefreshTokenManager';
import UserInfoManager from '@utils/storeManager/UserInfoManager';
import DeviceDataManager from '@utils/storeManager/DeviceInfoManager';
import AppEventManager from '@utils/storeManager/AppEventManager';

interface MobileData extends MobileDataState {
  accessToken?: string;
  refreshToken?: string;
}

interface SocialCode {
  provider: string;
  code: string;
}

declare global {
  /**
   * Now declare things that go in the global namespace,
   * or augment existing declarations in the global namespace.
   */
  interface Window {
    Kakao: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    VConsole: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    opera: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    MSStream: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    JavaScriptChannel:
      | undefined
      | {
          postMessage: (message: string) => void;
        };
    handlers: {
      initialize: (data: MobileData) => void;
      remove: () => void;
      userAccessLog: (userId?: string) => void;
      socialComplete: (data: SocialCode) => void;
      getFriendRanking?: (phones: { phoneNumbers: string[] }) => void;
      pushMessageMove: (params: {
        pushContentId: string | null;
        pushReservationId: string | null;
        pushContentType: string;
      }) => void;
      sendState?: (state: string) => void; // 스페이스게임
      refresh?: () => void; // 스페이스게임
      aosGoBack?: () => void; // 안드로이드 뒤로가기 웹에서 위임받아 처리
      movePath: (path: string) => void;
      updatePushStatus: (status: PushStatus) => void;
      parseAndNavigateToUrl: (encode: string) => void;
    };
    refreshCallbackQueue: (() => void)[]; // 스페이스게임
  }
}

export const makeHandlers = (): void => {
  if (typeof window !== 'undefined') {
    window.handlers = {
      sendState: () => {},
      movePath: (path: string) => {
        const moveUrl = path.startsWith('/') ? path : `/${path}`;

        AppEventManager.setAppEvent(moveUrl);
      },
      initialize: async (data: MobileData) => {
        DeviceDataManager.setDeviceData(data);

        if (!data.accessToken || !data.refreshToken) {
          return;
        }

        AccessTokenManager.setAccessToken(data.accessToken);
        RefreshTokenManager.setRefreshToken(data.refreshToken);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fetchList: Promise<any>[] = [
          usersApi.getUserInfo(),
          usersApi.postDeviceInfo({
            deviceId: data.deviceId,
            appVersion: data.appVersion,
            devicePlatform: data.devicePlatform,
            deviceToken: data.deviceToken,
          }),
        ];

        const [res] = await Promise.all(fetchList);

        if (res.data) {
          UserInfoManager.setUserInfo(res.data);
        }

        if (data.pushReservationId) {
          DeviceDataManager.setDeviceData({
            ...data,
            pushReservationId: null,
            pushContentType: 'NONE',
            pushContentId: null,
          });

          window.handlers.pushMessageMove({
            pushContentId: data.pushContentId,
            pushContentType: data.pushContentType,
            pushReservationId: data.pushReservationId,
          });
        }

        window.handlers.userAccessLog(res.data.userId);
      },
      pushMessageMove: async (params: {
        pushContentId: string | null;
        pushReservationId: string | null;
        pushContentType: string;
      }) => {
        if (params.pushReservationId) {
          await pushApi.postPushReservationClick({
            pushReservationId: params.pushReservationId,
          });
        }
        switch (params.pushContentType) {
          case 'STORY':
            AppEventManager.setAppEvent(`/story/${params.pushContentId}`);
            break;
          case 'SPACE_GAME':
            AppEventManager.setAppEvent(`/space`);
            break;
          case 'FOURCUT_RANKING':
            AppEventManager.setAppEvent(`/`);
            break;
          case 'HAPPY_HOUR_COUPON':
            AppEventManager.setAppEvent(
              `/promotion/detail/${params.pushContentId}`,
            );
            break;
          case 'MY_PAGE':
            AppEventManager.setAppEvent(`/profile/me`);
            break;
          case 'MY_PAGE_CARD_DETAIL':
            if (!params.pushContentId) {
              AppEventManager.setAppEvent(`/profile/me`);
              return;
            }
            AppEventManager.setAppEvent(
              `/profile-photo-cards/me/${params.pushContentId}`,
            );
            break;
          case 'CARD_DETAIL':
            if (!params.pushContentId) {
              AppEventManager.setAppEvent(`/`);
              return;
            }
            AppEventManager.setAppEvent(`/photo-card/${params.pushContentId}`);
            break;
          default:
            break;
        }
      },
      remove: () => {
        AccessTokenManager.removeToken();
        RefreshTokenManager.removeRefreshToken();
        DeviceDataManager.removeInfo();
      },
      userAccessLog: async (id?: string) => {
        const storageUser = UserInfoManager.getUserInfo();

        if (!storageUser) return;
        const userId = id || storageUser.userId;
        if (!userId) return;
        await usersApi.postUsersAccess({ userId });
      },
      socialComplete: (data: SocialCode) => {
        window.location.replace(
          `${window.location.origin}/social/complete?code=${data.code}&state=${data.provider}`,
        );
      },
      refresh() {
        OnResumeHandler.getInstance().runCallbacks();
      },
      updatePushStatus: (status: PushStatus) => {
        const { setPushStatus } = usePushStatusStore.getState();
        setPushStatus(status);
      },
      parseAndNavigateToUrl: (encodedData: string) => {
        if (!AccessTokenManager.getAccessToken()) return;

        const decodedData = JSON.parse(atob(encodedData));

        if (decodedData.url) {
          const { url } = decodedData;
          AppEventManager.setAppEvent(url);
        }
      },
    };
  }
};
