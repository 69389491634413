import { tagManager } from '@src/tags/utils/tagManager';

export const profileTags = {
  Profile_Instagram_Clicked: () => {
    // 다른유저프로필_인스타그램_클릭
    tagManager('Profile_Instagram_Clicked');
  },
  Profile_Tiktok_Clicked: () => {
    // 다른유저프로필_틱톡_클릭
    tagManager('Profile_Tiktok_Clicked');
  },
};
