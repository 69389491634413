import React from 'react';
import styled from 'styled-components';
import { Assets } from '@src/svg';
import Text from '@components/common/Text';
import PlayingUserTooltip from '@components/4cut-ranking/PlayingUserTooltip';
import useAppRouter from '@hooks/useAppRouter';
import useUserInfoStore from '@hooks/useUserInfoStore';
import { useApplyFormStore } from '@src/stores/4cut-ranking/applyStore';
import useGetGamePlayedCount from '@src/apis/querys/main-queries/useGetGamePlayedCount';
import { getColorValue } from '@styles/styleUtils';
import useDeviceStore from '@hooks/useDeviceStore';
import { googleTagManager } from '@src/tags';

const ButtonWrap = styled.div`
  position: relative;
  width: 100%;
  margin-top: auto;
  margin-bottom: 54px;

  div.tooltip-container {
    height: 36px;
  }

  div.button-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 8px;
    padding: 0 20px;
  }
`;

const ApplyButton = styled.button`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 10px 0 #040e37;
  border: 2px solid ${getColorValue('white')};
  background: #17131c;
  border-radius: 12px;
  color: ${getColorValue('white')};

  &:hover,
  &:active {
    background: ${getColorValue('gray90')};
  }
`;

const GameButton = styled.button`
  position: relative;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  backdrop-filter: blur(8px);
  border-radius: 12px;
  background: linear-gradient(
    270deg,
    #cde9ff 0%,
    #abbdfb 28%,
    #9aa6f8 57.5%,
    #9c66ff 100%
  );
  color: ${getColorValue('gray100')};
  box-shadow: 8px 6px 24px rgba(255, 20, 147, 0.5),
    -8px 10px 18px rgba(17, 149, 189, 0.4);

  &::after {
    content: '';
    position: absolute;
    inset: 2px;
    border-radius: 10px;
    background: ${getColorValue('white')};
  }

  &:hover,
  &:active {
    &::after {
      background: ${getColorValue('gray15')};
    }
  }

  > svg,
  > span {
    z-index: 2;
  }
`;

const MainCtaButtons = (): JSX.Element => {
  const router = useAppRouter();
  const [userInfo] = useUserInfoStore();
  const { resetData } = useApplyFormStore();

  const [deviceInfo] = useDeviceStore();
  const isApp = !!deviceInfo?.deviceToken;

  const { data } = useGetGamePlayedCount();

  const handleClickPlayGame = () => {
    router.push('/4cut-ranking/game');
  };

  const handleClickApply = () => {
    if (!isApp) {
      googleTagManager.Ranking_Apply_Button_Clicked_Web();
    }
    googleTagManager.Ranking_Apply_Button_Clicked();

    if (userInfo?.userId) {
      router.push('/4cut-ranking/apply');
    } else {
      // 웹 비회원 클릭 시 "비회원으로 시작하기" 버튼 노출된 로그인 화면으로 이동
      router.push('/login?anonymous4cutRankingApply=true');
    }
    resetData();
  };

  return (
    <ButtonWrap>
      <div className="tooltip-container">
        {!!data?.count && <PlayingUserTooltip count={data.count} />}
      </div>
      <div className="button-container">
        <ApplyButton onClick={handleClickApply}>
          <Assets.AddCard width={24} height={24} />
          <Text typo="body1_semiBold" color="white">
            랭킹 참가
          </Text>
        </ApplyButton>
        <GameButton onClick={handleClickPlayGame}>
          <Assets.GameConsole width={20} height={20} />
          <Text typo="body1_semiBold" color="gray100">
            지역 얼짱뽑기
          </Text>
        </GameButton>
      </div>
    </ButtonWrap>
  );
};

export default MainCtaButtons;
