import { usePathname } from 'next/navigation';
import { useCallback } from 'react';
import useDeviceStore from '@hooks/useDeviceStore';
import { updateOverCheck } from '@utils/updateCheck';
import ForcedUpdateModal from '@components/modal/ForcedUpdateModal';

const useForcedUpdate = (version: string) => {
  const pathname = usePathname();
  const [deviceInfo] = useDeviceStore();

  const UpdateModal = useCallback(() => {
    if (!deviceInfo) return null;
    const isUpdateRequired = !updateOverCheck(deviceInfo?.appVersion, version);

    if (!isUpdateRequired) return null;

    return <ForcedUpdateModal isOpen />;
  }, [pathname]);

  return UpdateModal;
};

export default useForcedUpdate;
