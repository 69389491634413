import TagManager from 'react-gtm-module';

export const tagManager = (
  event: string,
  params?: {
    title?: string;
    category?: string;
    memberType?: string;
    photoCardName?: string;
    picked?: string;
    round?: number;
    snsType?: string;
    cause?: string;
    lastStage?: number;
  },
) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...params,
    },
  });
};
