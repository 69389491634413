import { tagManager } from '@src/tags/utils/tagManager';

export const profileEditTags = {
  ProfileEdit_Region_View: () => {
    // 프로필수정_지역정보_바텀시트
    tagManager('ProfileEdit_Region_View');
  },
  ProfileEdit_Region_Submit: () => {
    // 프로필수정_지역정보_바텀시트_입력
    tagManager('ProfileEdit_Region_Submit');
  },
  ProfileEdit_Region_Exit: () => {
    // 프로필수정_지역정보_바텀시트_이탈
    tagManager('ProfileEdit_Region_Exit');
  },
};
