import Modal from '@components/modal/Modal';
import Text from '@components/common/Text';
import Spacing from '@components/common/Spacing';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import styled from 'styled-components';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import photoCard1 from '@public/images/fourced-update-and-push-bottom-sheet/서울.webp';
import photoCard2 from '@public/images/fourced-update-and-push-bottom-sheet/경상.webp';
import photoCard3 from '@public/images/fourced-update-and-push-bottom-sheet/충청강원.webp';
import photoCard4 from '@public/images/fourced-update-and-push-bottom-sheet/전라제주.webp';
import photoCard5 from '@public/images/fourced-update-and-push-bottom-sheet/인천경기.webp';
import { useEffect, useState } from 'react';
import { googleTagManager } from '@src/tags';

interface Props {
  isOpen: boolean;
}

const SwiperWrap = styled(Swiper)`
  .swiper-slide-active {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
`;

const ImageWrap = styled.div<{ $isActive: boolean }>`
  transition: all 0.3s ease;
  transform: scale(${({ $isActive }) => ($isActive ? 1.2 : 1)}) translateZ(0);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > img {
    @media screen and (max-width: 360px) {
      width: 70px;
    }

    @media screen and (min-width: 361px) and (max-width: 393px) {
      width: 75px;
    }

    @media screen and (min-width: 394px) and (max-width: 414px) {
      width: 80px;
    }

    @media screen and (min-width: 415px) and (max-width: 430px) {
      width: 85px;
    }

    @media screen and (min-width: 431px) {
      width: 100px;
    }

    height: auto;
    image-rendering: crisp-edges; /* 구형 브라우저 호환 */
  }
`;

const PHOTO_CARD_IMAGES = [
  photoCard1,
  photoCard2,
  photoCard3,
  photoCard4,
  photoCard5,
  photoCard1,
  photoCard2,
  photoCard3,
  photoCard4,
  photoCard5,
];

const ForcedUpdateModal = ({ isOpen }: Props) => {
  function redirectToAppStore() {
    googleTagManager.ForceUpdate_Popup_Clicked();

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.apr.photogray';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href =
        'https://apps.apple.com/kr/app/%ED%8F%AC%ED%86%A0%EA%B7%B8%EB%A0%88%EC%9D%B4/id6478901226';
    }
  }

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    googleTagManager.ForceUpdate_Popup_View();
  }, []);

  return (
    <Modal
      blackBackdrop
      isOpen={isOpen}
      submitBtn={{
        label: '업데이트 하러가기',
        onClick: redirectToAppStore,
      }}
      content={
        <>
          <Text as={'h1'} typo={'heading1_semiBold'} textAlign={'center'}>
            업데이트 안내
          </Text>
          <Spacing size={10} />
          <SwiperWrap
            slidesPerView={4.8}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            centeredSlides={true}
            loop={true}
            modules={[Autoplay]}
            onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
            style={{
              padding: '20px 0',
              margin: '0 -12%',
              width: '124%',
              position: 'relative',
              left: '-3%',
            }}
          >
            {PHOTO_CARD_IMAGES.map((src, index) => (
              <SwiperSlide key={index}>
                <ImageWrap $isActive={activeIndex === index}>
                  <Image
                    src={src}
                    alt={''}
                    sizes="(min-width: 768px) 75px, 100vw"
                  />
                </ImageWrap>
              </SwiperSlide>
            ))}
          </SwiperWrap>
          <Spacing size={10} />
          <Text as={'p'} typo={'body1'} textAlign={'center'}>
            이제 포컷랭킹을{' '}
            <Text color={'primary.purple'} typo={'body1_bold'}>
              지역별
            </Text>
            로 즐길 수 있어요!
            <br />
            지역별 포토카드 순위부터 나의 랭킹까지,
            <br />
            새로워진 포컷랭킹을 만나보세요!
          </Text>
        </>
      }
    />
  );
};

export default ForcedUpdateModal;
