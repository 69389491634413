import { DateTime } from 'luxon';
import postPageViewEvent from '@src/apis/statisticsApi';
import { tagManager } from '@src/tags/utils/tagManager';

// memo: 태그 변경 시 관리자 페이지의 페이지뷰 통계 코드도 수정 필요
const staticPageViewEvent: Partial<{ [Key in RoutePath]: string }> = {
  '/': 'Page_View_Ranking', // 화면조회_네컷랭킹 (GA 태그는 서비스명 변경 없이 유지 중)
  '/ranking': 'Page_View_RealTimeRanking', // 화면조회_실시간랭킹
  '/ranking?tab=hall-of-fame': 'Page_View_HallOfFame', // 화면조회_명예의 전당
  '/home': 'Page_View_Home', // 화면조회_홈
  '/home/reward': 'Page_View_Reward', // 화면조회_포토그레이리워드
  '/home/history/stamp': 'Page_View_Stamp', // 화면조회_스탬프내역
  '/home/history/coupon': 'Page_View_Coupon', // 화면조회_쿠폰내역
  '/profile/me': 'Page_View_MyRanking', // 화면조회_MY랭킹
  '/sign-up/step1': 'Page_View_Signup_Agreement', // 회원가입_약관동의
  '/sign-up/step2': 'Page_View_Signup_Nickname', // 회원가입_닉네임설정
  '/sign-up/step3': 'Page_View_Signup_Region', // 회원가입_지역정보
  '/sign-up/step4': 'Page_View_Signup_PushAgree', // 회원가입_알림동의
  '/start': 'Page_View_Signup_Start', // 회원가입_시작하기
  '/story': 'Page_View_Stories', // 화면조회_NewStory
  '/setting': 'Page_View_Setting', // 화면조회_환경설정
  '/setting/help': 'Page_View_Help', // 화면조회_고객센터
  '/push-history': 'Page_View_PushHistory', // 화면조회_알림
  '/notice': 'Page_View_Notice', // 화면조회_공지사항
  '/space': 'Page_View_SpaceGame_Home', // 화면조회_우주게임홈
  '/space/game': 'Page_View_SpaceGame_Play', // 화면조회_우주게임플레이
};

const postPageViewEventToAdmin = async (pageViewEvent: string) => {
  await postPageViewEvent.postPageViewEvent({
    pageId: pageViewEvent,
    eventDateTime: DateTime.now().toString(),
  });
};

export const pageViewTags = {
  Page_View: (path: RoutePath) => {
    const pageViewEvent = staticPageViewEvent[path];
    if (pageViewEvent) {
      tagManager(pageViewEvent);
      postPageViewEventToAdmin(pageViewEvent);
    }
  },
  Page_View_Apply: (
    step:
      | 'agreement'
      | 'certificate'
      | 'kcp'
      | 'category'
      | 'photo-name'
      | 'photo',
  ) => {
    // 화면조회_포토카드등록중
    switch (step) {
      case 'agreement':
        return tagManager('Page_View_Apply_Agreement');
      case 'certificate':
        return tagManager('Page_View_Apply_Certificate_Start');
      case 'kcp':
        return tagManager('Page_View_Apply_Certificate_End');
      case 'category':
        postPageViewEventToAdmin('Page_View_Apply_Category');
        return tagManager('Page_View_Apply_Category');
      case 'photo-name':
        postPageViewEventToAdmin('Page_View_Apply_PhotoCardName');
        return tagManager('Page_View_Apply_PhotoCardName');
      case 'photo':
        postPageViewEventToAdmin('Page_View_Apply_PhotoUpload');
        return tagManager('Page_View_Apply_PhotoUpload');
      default:
    }
  },
  Page_View_PhotoCardDetail: () => {
    // 화면조회_포토카드상세보기
    tagManager('Page_View_PhotoCardDetail');
    postPageViewEventToAdmin('Page_View_PhotoCardDetail');
  },
  Page_View_ApplyCompleted: () => {
    // 화면조회_포토카드등록완료
    tagManager('Page_View_ApplyCompleted');
  },
  Page_View_Profile: () => {
    // 화면조회_다른유저프로필
    tagManager('Page_View_Profile');
  },
  Page_View_Story: (params: { title: string }) => {
    // 화면조회_NewStory상세
    tagManager('Page_View_Story', params);
  },
  Page_View_Ranking_Web: () => {
    // 화면조회_포컷랭킹(웹)
    tagManager('Page_View_Ranking_Web');
  },
  Page_View_RealTimeRanking_Web: () => {
    // 화면조회_실시간랭킹(웹)
    tagManager('Page_View_RealTimeRanking_Web');
  },
  Page_View_HallOfFame_Web: () => {
    // 화면조회_명예의 전당(웹)
    tagManager('Page_View_HallOfFame_Web');
  },
  Page_View_HappyHour: () => {
    // 화면조회_해피아워쿠폰
    tagManager('Page_View_HappyHour');
  },
};
