import axiosInstance from '@src/axios';
import { ResultOfRoundType } from '@src/stores/4cut-ranking/gameStore';

// 포컷랭킹 게임 생성(랜덤 16장) API
const postStartGame = async (data: {
  region: RankingRegionType;
  photoCardCategory: CategoryType;
}): Promise<
  AInstance.Res<{
    rankingGameId: string;
    photoCards: PhotoCardType[];
  }>
> => axiosInstance.post('/api/ranking/v2/games', data);

// 포컷랭킹 게임 생성(지정된 카드 1장 포함 16장) API
const postCustomStartGame = async (data: {
  photoCardId: string;
}): Promise<
  AInstance.Res<{
    rankingGameId: string;
    photoCards: PhotoCardType[];
    region: RankingRegionType;
  }>
> =>
  axiosInstance.post(
    `/api/ranking/v2/games/with-photo-card/${data.photoCardId}`,
  );

// 포컷랭킹 게임 결과 반영 API
const postFinishGame = async (data: {
  rankingGameId: string;
  resultOfRound16: ResultOfRoundType[];
  resultOfRound8: ResultOfRoundType[];
  resultOfRound4: ResultOfRoundType[];
  resultOfFinal: ResultOfRoundType;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.post('/api/ranking/v2/games/result', data);

// 실시간 핫(현재기준 24시간 가장 많은 사용자 플레이)한 지역, 카테고리 API
const getHotRegion = async (): Promise<
  AInstance.Res<{
    region: RankingRegionType;
    photoCardCategory: CategoryType;
  }>
> => axiosInstance.get('/api/ranking/v2/games/hot');

// 실시간 핫한 지역, 카테고리의 1위, 2위 포토카드 정보 API
const getRegionTopTwoCards = async (params: {
  region: RankingRegionType;
  photoCardCategory: CategoryType;
}): Promise<
  AInstance.Res<{
    firstPhotoCard: PhotoCardType;
    secondPhotoCard: PhotoCardType;
  }>
> => axiosInstance.get('/api/ranking/v2/games/hot/ranking', { params });

// 실시간 랭킹(지역, 카테고리 별) 조회 API
const getRegionTopCards = async (params: {
  region: RankingRegionType;
  photoCardCategory: CategoryType;
  limit?: number;
}): Promise<AInstance.Res<PhotoCardType[]>> =>
  axiosInstance.get('/api/ranking/v2/games/ranking', { params });

// 포컷랭킹 게임 생성 수 API
const getGamePlayedCount = async (): Promise<
  AInstance.Res<{
    count: number;
  }>
> => axiosInstance.get('/api/ranking/v2/games/count');

export default {
  postStartGame,
  postFinishGame,
  getHotRegion,
  getRegionTopTwoCards,
  getRegionTopCards,
  getGamePlayedCount,
  postCustomStartGame,
};
