import Instagram from '@icons/sns/instagram.svg';
import InstagramLarge from '@icons/sns/instagram-large.svg';
import Tiktok from '@icons/sns/tiktok.svg';
import TiktokLarge from '@icons/sns/tiktok-large.svg';

export default {
  Instagram,
  InstagramLarge,
  Tiktok,
  TiktokLarge,
};
