import { DefaultSeoProps } from 'next-seo';
import seo from '@public/locales/ko/seo.json';

const getShortenEnv = (env?: string): string => {
  if (env === 'development') return '[dev]';
  if (env === 'stage') return '[stage]';
  return '';
};

const DEFAULT_SEO: DefaultSeoProps = {
  title: `${
    process.env.NEXT_PUBLIC_APP_ENV === 'development' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'stage'
      ? getShortenEnv(process.env.NEXT_PUBLIC_APP_ENV)
      : ''
  }${seo.title}`,
  description: seo.description,
  canonical: process.env.NEXT_PUBLIC_SITE_URL,
  openGraph: {
    type: 'website',
    locale: 'ko_KR',
    url: process.env.NEXT_PUBLIC_SITE_URL,
    title: seo.openGraph.title,
    description: seo.openGraph.description,
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_SITE_URL}/images/banner.png`,
      },
    ],
    siteName: seo.openGraph.siteName,
  },
};

export default DEFAULT_SEO;
