import ProfileCrown from '@icons/profile/profile-crown.svg';
import ProfileGraph from '@icons/profile/profile-graph.svg';
import ProfileCalendar from '@icons/profile/profile-calendar.svg';
import PickTooltip from '@icons/profile/pick-tooltip.svg';
import WinTooltip from '@icons/profile/win-tooltip.svg';

export default {
  ProfileCrown,
  ProfileGraph,
  ProfileCalendar,
  PickTooltip,
  WinTooltip,
};
