import React from 'react';
import styled from 'styled-components';
import PhotoCard from '@components/photo-card/PhotoCard';
import VS from '@components/4cut-ranking/VS';
import {
  leftBounceAnimation,
  rightBounceAnimation,
} from '@styles/animationStyle';
import useGetHotRegionTopTwoCards from '@src/apis/querys/main-queries/useGetHotRegionTopTwoCards';

const PhotoCardVsWrap = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 274px;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 32px;
  overflow: hidden;

  .photo-card {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform;
    scale: 1.1;
  }

  > div.front {
    display: flex;
    justify-content: center;
    perspective: 500px;

    > .photo-card.left {
      transform: rotateY(35deg);
      filter: drop-shadow(-10px 0 10px #0a070f)
        drop-shadow(-10px 0 20px #0a070f);
      animation: ${leftBounceAnimation} 3s ease infinite;
    }

    > .photo-card.right {
      transform: rotateY(-35deg);
      filter: drop-shadow(10px 0 10px #0a070f) drop-shadow(10px 0 20px #0a070f);
      animation: ${rightBounceAnimation} 3s ease infinite;
    }
  }

  > div.back {
    position: absolute;
    width: 120%;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    perspective: 300px;
    opacity: 0.5;

    > .photo-card.left {
      transform: rotateY(35deg);
    }

    > .photo-card.right {
      transform: rotateY(-35deg);
    }
  }
`;

const MainPhotoCardVs = (): JSX.Element => {
  const { firstPhotoCard, secondPhotoCard } = useGetHotRegionTopTwoCards();

  return (
    <PhotoCardVsWrap>
      <VS />
      <div className="back">
        <PhotoCard size="XS" className="photo-card left" />
        <PhotoCard size="XS" className="photo-card right" />
      </div>
      <div className="front">
        <PhotoCard
          photoCardInfo={firstPhotoCard}
          size="XS"
          className="photo-card left"
        />
        <PhotoCard
          photoCardInfo={secondPhotoCard}
          size="XS"
          className="photo-card right"
        />
      </div>
    </PhotoCardVsWrap>
  );
};

export default MainPhotoCardVs;
