import { CSSObject } from 'styled-components';
import { themeV2 } from '@styles/theme_v2';

export type HeaderColorType = keyof typeof themeV2.headerColor;

export type ColorKeys = keyof typeof themeV2.colorV2;
export type NestedColorKeys<K extends ColorKeys> =
  K extends keyof typeof themeV2.colorV2
    ? keyof (typeof themeV2.colorV2)[K] extends string
      ? `${K}.${keyof (typeof themeV2.colorV2)[K]}`
      : never
    : never;
export type Color = ColorKeys | NestedColorKeys<ColorKeys>;

/**
 * 주어진 색상 이름에 해당하는 색상 코드를 반환
 *
 * @param {string} color - 색상 이름 (예: 'gray90', 'primary.purple').
 * @returns {string} 해당 색상에 대한 16진수 색상 코드 (예: '#202027').
 *
 * @example
 * getColorCode('primary.purple'); // '#8F5EE9'
 * */
export const getColorValue = (color: Color): string | undefined => {
  if (typeof themeV2.colorV2[color as ColorKeys] === 'string') {
    return themeV2.colorV2[color as ColorKeys] as string;
  }
  if (color.includes('.')) {
    const [parentKey, childKey] = color.split('.') as [ColorKeys, string];
    const parentColor = themeV2.colorV2[parentKey];
    if (typeof parentColor === 'object' && childKey in parentColor) {
      return parentColor[childKey as keyof typeof parentColor];
    }
  }
  return undefined;
};

export type FontFamily = keyof typeof themeV2.fontFamilyV2;
export type Size = keyof typeof themeV2.fontSizeV2;
export type Weight = keyof typeof themeV2.fontWeightV2;

export type Typography = `${Size}_${Weight}` | Size;

/**
 * 'fontSize_fontWeight' 형식의 문자열을 입력받아 해당하는 CSS 폰트 스타일 객체를 반환
 * (regular의 경우 '_regular' 생략 가능)
 *
 * @param {string} typo - 'Size_Weight' 형식의 문자열 (예: 'title1_semiBold').
 * @returns {CSSObject} 해당 폰트 크기와 두께를 포함하는 CSS 스타일 객체.
 *
 * @example
 * getFontStyle('title1_semiBold'); // { fontSize: '24px', lineHeight: '34px', fontWeight: '600' }
 * */
export const getFontValue = (typo: Typography): CSSObject => {
  if (typo.includes('_')) {
    const [size, weight] = typo.split('_') as [Size, Weight];
    return {
      ...themeV2.fontSizeV2[size],
      fontWeight: themeV2.fontWeightV2[weight],
    };
  }
  if (Object.keys(themeV2.fontSizeV2).includes(typo)) {
    return {
      ...themeV2.fontSizeV2[typo as Size],
      fontWeight: themeV2.fontWeightV2.regular,
    };
  }
  return {};
};
