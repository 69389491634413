import arrow from './arrow';
import checkbox from './checkbox';
import fourCutRanking from './4cut-ranking';
import profile from './profile';
import sns from './sns';
import common from './common';

export const Assets = {
  ...arrow,
  ...checkbox,
  ...fourCutRanking,
  ...profile,
  ...sns,
  ...common,
};
