import CheckIcon from '@icons/check-box/check.svg';
import SquareWhiteDefaultIcon from '@icons/check-box/square-white-default.svg';
import SquareWhiteActiveIcon from '@icons/check-box/square-white-active.svg';
import SquareBlackDefaultIcon from '@icons/check-box/square-black-default.svg';
import SquareBlackActiveIcon from '@icons/check-box/square-black-active.svg';
import SquareFocusIcon from '@icons/check-box/square-focus.svg';
import CircleWhiteDefaultIcon from '@icons/check-box/circle-white-default.svg';
import CircleWhiteActiveIcon from '@icons/check-box/circle-white-active.svg';
import CircleBlackDefaultIcon from '@icons/check-box/circle-black-default.svg';
import CircleBlackActiveIcon from '@icons/check-box/circle-black-active.svg';

export default {
  CheckIcon,
  SquareWhiteDefaultIcon,
  SquareWhiteActiveIcon,
  SquareBlackDefaultIcon,
  SquareBlackActiveIcon,
  SquareFocusIcon,
  CircleWhiteDefaultIcon,
  CircleWhiteActiveIcon,
  CircleBlackDefaultIcon,
  CircleBlackActiveIcon,
};
