import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QR } from '@src/queryKeys';
import usersApi from '@src/apis/usersApi';

const useGetMyUniqueCode = (): UseQueryResult<string, unknown> =>
  useQuery({
    queryKey: [QR],
    queryFn: async () => {
      const res = await usersApi.getMyUniqueCode();
      return res.data.userUniqueCode;
    },
    ...{ suspense: true },
  });

export default {
  useGetMyUniqueCode,
};
