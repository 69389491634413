import React from 'react';
import styled from 'styled-components';
import { Assets } from '@src/svg';
import Text from '@components/common/Text';
import GaugeBar from '@components/4cut-ranking/GaugeBar';
import useGetHotRegionTopTwoCards from '@src/apis/querys/main-queries/useGetHotRegionTopTwoCards';

const GaugeWrap = styled.div`
  width: 295px;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const NicknameWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -4px;

  svg {
    min-width: 24px;
  }

  > div {
    background: linear-gradient(106.99deg, #312c3c 17.68%, #120e19 90.67%);
    display: flex;
    align-items: flex-end;
    width: 140px;
    height: 32px;
    gap: 4px;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.left {
      justify-content: flex-start;
      border-radius: 99px 160px 20px 99px;
      box-shadow: 1px 0 4px 0 #a479f5;
      padding: 0 20px 9px 10px;
    }

    &.right {
      justify-content: flex-end;
      border-radius: 160px 99px 99px 20px;
      box-shadow: 1px 0 4px 0 #74b5ed;
      padding: 0 10px 9px 20px;
    }
  }
`;

const MainGaugeBar = (): JSX.Element => {
  const { firstPhotoCard, secondPhotoCard } = useGetHotRegionTopTwoCards();

  const firstWinCount = firstPhotoCard?.winCount || 1;
  const secondWinCount = secondPhotoCard?.winCount || 1;
  const fullCount = firstWinCount + secondWinCount || 2;
  const leftPercent = (firstWinCount / fullCount) * 100;

  return (
    <GaugeWrap>
      <NicknameWrap>
        <div className="left">
          <Assets.Crown1 width={24} height={19} />
          <Text typo="caption2_semiBold" color="white">
            {firstPhotoCard?.photoCardName || ''}
          </Text>
        </div>
        <div className="right">
          <Text typo="caption2_semiBold" color="white">
            {secondPhotoCard?.photoCardName || ''}
          </Text>
          <Assets.Crown2 width={24} height={19} />
        </div>
      </NicknameWrap>
      <GaugeBar leftPercent={leftPercent} />
    </GaugeWrap>
  );
};

export default MainGaugeBar;
