import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { postAppMessage } from '@utils/appMessage';
import { CMD } from '@src/constants/appMessageCmd';
import AccessTokenManager from '@utils/storeManager/AccessTokenManager';
import RefreshTokenManager from '@utils/storeManager/RefreshTokenManager';
import UserInfoManager from '@utils/storeManager/UserInfoManager';
import * as Sentry from '@sentry/browser';
import AppEventManager from '@utils/storeManager/AppEventManager';

const INVALID_ACCESS_TOKEN = 'S4_0001';
const INVALID_REFRESH_TOKEN = 'S4_0002';
const EXPIRED_ACCESS_TOKEN = 'S4_0003';
const EXPIRED_REFRESH_TOKEN = 'S4_0004';
const SUSPENDED_USER = 'S4_0007';
const WITHDRAWAL_USER = 'S4_0008';
const KICKED_USER = 'S4_0009';

const handleForceLogout = () => {
  AccessTokenManager.setAccessToken('');
  RefreshTokenManager.setRefreshToken('');
  UserInfoManager.setUserInfo(null);

  const currentPath = window.location.pathname;
  const currentQuery = window.location.search;
  const redirectUri = btoa(encodeURIComponent(`${currentPath}${currentQuery}`));

  AppEventManager.setAppEvent(`/login?redirectUri=${redirectUri}`);
};

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  timeout: 10000,
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params, { indices: false }),
});

let isTokenRefreshing = false;
let requestQueue: {
  resolve: (value: Promise<AInstance.Res<any>> | any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  reject: (reason?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}[] = [];

axiosInstance.interceptors.request.use(
  // @ts-expect-error axios 설정
  (config: AxiosRequestConfig) => {
    // SSR 실행 시에는 토큰 포함하지 않음
    if (typeof window !== 'undefined') {
      const token = AccessTokenManager.getAccessToken();
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${JSON.parse(token)}`,
        };
      }
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalConfig = error.config;
    if (error.response) {
      /// @ts-expect-error axios 설정
      const { code } = error.response.data;

      if (code === EXPIRED_ACCESS_TOKEN) {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true;

          const refreshToken = localStorage.getItem('refreshToken');
          if (refreshToken) {
            try {
              const response = await axiosInstance.post(
                '/api/users/token/reissue',
                {
                  refreshToken: JSON.parse(refreshToken),
                },
                {
                  headers: { Authorization: undefined },
                },
              );

              const { accessToken, refreshToken: newRefreshToken } =
                response.data;
              AccessTokenManager.setAccessToken(accessToken);
              RefreshTokenManager.setRefreshToken(newRefreshToken);
              postAppMessage({
                cmd: CMD.STORE_AUTH_TOKEN,
                accessToken,
                refreshToken: newRefreshToken,
              });

              isTokenRefreshing = false;

              // @ts-expect-error axios 설정
              originalConfig.headers.Authorization = `Bearer ${accessToken}`;

              requestQueue.forEach((p) => {
                p.resolve(`Bearer ${accessToken}`);
              });

              requestQueue = [];

              // @ts-expect-error axios 설정
              return await Promise.resolve(axiosInstance(originalConfig));
            } catch (reissueError) {
              isTokenRefreshing = false;
              requestQueue.forEach((p) => p.reject(reissueError));
              requestQueue = [];
              // handleForceLogout();
              return Promise.reject(reissueError);
            }
          }
        }
        return new Promise((resolve, reject) => {
          requestQueue.push({
            resolve: (token: string) => {
              // @ts-expect-error axios 설정
              originalConfig.headers.Authorization = token;

              // @ts-expect-error axios 설정
              resolve(axiosInstance(originalConfig));
            },
            reject: (err: AxiosError) => reject(err),
          });
        });
      }
      if (
        [
          INVALID_ACCESS_TOKEN,
          INVALID_REFRESH_TOKEN,
          EXPIRED_REFRESH_TOKEN,
          SUSPENDED_USER,
          WITHDRAWAL_USER,
          KICKED_USER,
        ].includes(code) ||
        error.response.status === 403
      ) {
        handleForceLogout();
      } else if (
        error.response.status === 400 ||
        error.response.status === 401
      ) {
        Sentry.captureException(error);
        return {
          status: error.response.status,
          statusText: error.response.statusText,
        };
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
