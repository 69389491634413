import * as React from 'react';
import Lottie from 'lottie-react';
import { CSSObject } from 'styled-components';
import progressBalls from '@lotties/61172-progress-balls.json';

interface Props {
  style?: CSSObject;
}

const ProgressBalls: React.FC<Props> = ({ style }) => {
  return (
    <Lottie
      loop
      role="img"
      animationData={progressBalls}
      style={{ width: '65px', height: '65px', margin: 'auto', ...style }}
    />
  );
};

export default ProgressBalls;
