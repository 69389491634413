import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/utils/atomWithStorage';
import { isClient } from '@utils/window';

interface PathType {
  prevPath: string | undefined;
  currentPath: string | undefined;
}

const storage = isClient
  ? createJSONStorage(() => sessionStorage)
  : {
      getItem: () => '',
      setItem: () => {},
      removeItem: () => {},
    };

export const pathAtom = atomWithStorage<PathType>(
  'path',
  {
    prevPath: undefined,
    currentPath: undefined,
  },
  storage as SyncStorage<PathType>,
);
