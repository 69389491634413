import { CouponSvgWrap } from '@components/common/styles/coupon.style';
import React from 'react';

interface Props {
  uniqueKey: string; // svg mask id 지정용
  $borderColor?: string; // 테두리 색상
  $dashColor?: string; // 점선 색상
  $fillColor?: string; // 배경 색상
  $boxShadow?: string; // 그림자
  $isBlur?: boolean;
  svgWidth: number;
  svgHeight: number;
  leftWidth: number;
}

/**
 * 쿠폰의 영역이 좌우로 나뉘어있는 쿠폰 UI
 * */
const HorizontalCouponSvg = ({
  uniqueKey,
  $boxShadow,
  $fillColor,
  $borderColor,
  $dashColor,
  $isBlur,
  svgWidth,
  svgHeight,
  leftWidth,
}: Props) => {
  return (
    <CouponSvgWrap $boxShadow={$boxShadow} $isBlur={$isBlur}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        width={svgWidth}
        height={svgHeight}
      >
        {/* svg mask */}
        <mask id={`ticket-mask-horizontal-${uniqueKey}`}>
          <rect
            x="0"
            y="0"
            width={svgWidth}
            height={svgHeight}
            rx="15"
            ry="15"
            fill="white"
          />
          <circle cx={leftWidth} cy="0" r="8" fill="black" />
          <circle cx={leftWidth} cy={svgHeight} r="8" fill="black" />
        </mask>

        {/* 직사각형 */}
        <rect
          x="0"
          y="0"
          width={svgWidth}
          height={svgHeight}
          rx="15"
          ry="15"
          fill={$fillColor}
          stroke={$borderColor}
          strokeWidth="2"
          mask={`url(#ticket-mask-horizontal-${uniqueKey})`}
        />
        {/* 테두리용 반원 - 위 */}
        <circle
          cx={leftWidth}
          cy="0"
          r="9"
          fill={$borderColor}
          mask={`url(#ticket-mask-horizontal-${uniqueKey})`}
        />
        {/* 테두리용 반원 - 아래 */}
        <circle
          cx={leftWidth}
          cy={svgHeight}
          r="9"
          fill={$borderColor}
          mask={`url(#ticket-mask-horizontal-${uniqueKey})`}
        />

        {/* 세로 점선 */}
        <path
          d={`M${leftWidth} 33L${leftWidth} ${svgHeight - 32}`}
          stroke={$dashColor}
          strokeWidth="1.5"
          strokeDasharray="4 8"
        />
      </svg>
    </CouponSvgWrap>
  );
};

export default HorizontalCouponSvg;
