import { useMemo } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

interface Return
  extends Omit<NextRouter, 'replace' | 'back' | 'push' | 'query'> {
  replace(
    path: RoutePath | { query: {} },
    as?: URL,
    options?: TransitionOptions,
  ): void;
  back(): void;
  push(path: RoutePath): void;
  query: NextParsedUrlQuery;
}

const useNavigator = (): Return => {
  const router = useRouter();
  return useMemo(() => {
    return {
      ...router,
      back() {
        router.back();
      },
      push(path: RoutePath) {
        router.push(path);
      },
      replace(path: RoutePath | { query: {} }, as, options) {
        router.replace(path, as, options);
      },
      query: router.query,
    };
  }, [router]);
};
export default useNavigator;
