import styled, { css } from 'styled-components';
import { getFontValue, HeaderColorType } from '@styles/styleUtils';

export const HeaderWrap = styled.header<{
  $headerColor?: HeaderColorType;
  $showWebBanner?: boolean;
}>`
  ${({ theme, $headerColor }) => theme.headerColor[$headerColor || 'white']};
  width: 100%;
  height: ${({ theme }) => theme.headerHeight.computed};
  min-height: ${({ theme }) => theme.headerHeight.computed};
  padding-top: ${({ theme }) => theme.safeArea.top};
  z-index: ${({ theme }) => theme.zIndex.header};
  position: fixed;
  top: 0;
  left: 0;
  ${({ $showWebBanner }) =>
    $showWebBanner
      ? css`
          position: sticky;
        `
      : css`
          position: fixed;
        `}

  > div.header-content {
    ${({ theme }) => theme.headerSize};
    display: flex;
    align-items: center;
  }

  svg {
    cursor: pointer;
  }
`;

export const CenterTitleWrap = styled.h1`
  ${getFontValue('body1_semiBold')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
`;

export const BackButtonWrap = styled.button`
  background: none;
  width: 24px;
  height: 24px;
  padding: 0;
  color: inherit;
`;

export const RightButtonContainerWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 8px 0 8px 8px;
`;
