import axiosInstance from '@src/axios';

const postPushReservationClick = async (data: {
  pushReservationId: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.post('/api/push/reservation/click', data);

export default {
  postPushReservationClick,
};
