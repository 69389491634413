import styled from 'styled-components';
import More from '@icons/mobile/more.svg';
import Text from '@components/common/Text';

const FullSizeBarcodeWrap = styled.button`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  gap: 4px;
`;

interface Props {
  onClick?: () => void;
}

const FullSizeBarcodeButton = ({ onClick }: Props): JSX.Element => {
  return (
    <FullSizeBarcodeWrap onClick={onClick}>
      <More />
      <Text typo="body3_medium" color="gray40">
        크게보기
      </Text>
    </FullSizeBarcodeWrap>
  );
};

export default FullSizeBarcodeButton;
