import { useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import Barcode from 'react-barcode';
import styled from 'styled-components';
import classNames from 'classnames';
import {
  BackgroundWrap,
  BarcodeWrap,
  CancelButton,
  CancelWrap,
  DimmedWrap,
  ExpiredInfoWrap,
} from './styles/barcodeModal.style';
import { barcodeAtom } from '@src/stores/barcode';
import Logo from '@icons/mobile/logo.svg';
import { convertISOStringToSeoulLongDateTime } from '@utils/dateUtils';
import { postAppMessage } from '@utils/appMessage';
import { GlobalPortal } from '@components/portal/GlobalPortal';
import FullSizeBarcodeButton from '@components/common/FullSizeBarcodeButton';
import CloseIcon from '@icons/mobile/modal/close-icon.svg';
import { ResCouponType } from '@src/apis/types/coupon';
import { getColorValue } from '@styles/styleUtils';
import { CMD } from '@src/constants/appMessageCmd';
import { googleTagManager } from '@src/tags';
import CouponUI from '@components/common/coupon-svg-ui/CouponUI';
import * as React from 'react';

const FullSizeBarcodeWrap = styled.div`
  position: fixed;
  transform: translate(-50%, -50%) rotate(90deg);
  top: 50%;
  left: 50%;
  width: 100vh;
  height: 100vw;
  background-color: ${getColorValue('white')};
  z-index: 21;
  transform-origin: center center;
`;

const TopComponent = ({
  value,
  isFullSize = false,
  handleClose,
}: {
  value: ResCouponType;
  isFullSize?: boolean;
  handleClose?: () => void;
}) => {
  return (
    <BarcodeWrap
      className={classNames({
        full: isFullSize,
      })}
    >
      <div
        className={classNames({
          title: true,
          left: isFullSize,
        })}
      >
        <Logo onClick={handleClose} />
        {isFullSize && <CloseIcon onClick={handleClose} />}
      </div>
      <div className="description">
        <span>{value.title}</span> 쿠폰
      </div>
      <Barcode width={!isFullSize ? 2.2 : 5.2} value={value.uniqueCode} />
      <div className="barcode-value">
        <span
          className={classNames({
            'code': true,
            'is-center': !isFullSize,
          })}
        >
          {value.uniqueCode.replace(/(.{4})/g, '$1 ').replace(/ - $/g, '')}
        </span>
        {!isFullSize && (
          <FullSizeBarcodeButton
            onClick={() => {
              googleTagManager.Coupon_Barcode_Zoom();
              handleClose?.();
            }}
          />
        )}
      </div>
    </BarcodeWrap>
  );
};

const BottomComponent = ({ value }: { value: ResCouponType }) => {
  return (
    <ExpiredInfoWrap>
      <div className="expired-info">
        <div className="expired-time">
          {convertISOStringToSeoulLongDateTime(value.expiredDateTime)}까지 사용
          가능
        </div>
        <span className="expired-description">
          * 사용 후 취소, 환불이 불가능합니다
        </span>
      </div>
    </ExpiredInfoWrap>
  );
};

const BarcodeModal = (): JSX.Element => {
  const [isModal, setIsModal] = useState(false);
  const [barcodeData, setBarcodeData] = useAtom(barcodeAtom);

  const [isFullSize, setIsFullSize] = useState(false);

  useEffect(() => {
    if (barcodeData?.uniqueCode) {
      setIsModal(true);
    }
  }, [barcodeData]);

  useEffect(() => {
    postAppMessage({
      cmd: CMD.BARCODE_MODE,
      bright: isModal,
    });
  }, [isModal]);

  const closeModal = useCallback(() => {
    setIsModal(false);
    setTimeout(() => {
      setBarcodeData(null);
    }, 300);
  }, [isModal, barcodeData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DimmedWrap className={isModal ? 'up' : 'down'} />
      <BackgroundWrap className={isModal ? 'up' : 'down'}>
        {barcodeData &&
          (!isFullSize ? (
            <>
              <CouponUI
                uniqueKey={barcodeData.couponId}
                $borderColor={'none'}
                $boxShadow={'0 2px 6px rgba(0, 0, 0, 0.09)'}
                handleClick={() => setIsFullSize((prev) => !prev)}
                verticalContent={{
                  top: <TopComponent value={barcodeData} />,
                  bottom: <BottomComponent value={barcodeData} />,
                }}
              />
              <CancelWrap onClick={closeModal}>
                <CancelButton />
                <span className="cancel-text">이전 화면으로 가기</span>
              </CancelWrap>
            </>
          ) : (
            <GlobalPortal.Consumer>
              <FullSizeBarcodeWrap>
                <div>
                  <TopComponent
                    isFullSize={isFullSize}
                    value={barcodeData}
                    handleClose={() => setIsFullSize((prev) => !prev)}
                  />
                </div>
                <div>
                  <BottomComponent value={barcodeData} />
                </div>
              </FullSizeBarcodeWrap>
            </GlobalPortal.Consumer>
          ))}
      </BackgroundWrap>
    </>
  );
};

export default BarcodeModal;
