import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation';
import useAccessToken from '@hooks/useAccessToken';
import useUserInfoStore from '@hooks/useUserInfoStore';
import useDeviceStore from '@hooks/useDeviceStore';
import useAppEventRouter from '@hooks/useAppEventRouter';
import {
  agreementValidation,
  nicknameValidation,
  phoneNumberValidation,
} from '@utils/loginValidation';
import { postAppMessage } from '@utils/appMessage';
import { postAppStatusTextColor } from '@src/utils/postAppMessageUtils';
import { CMD } from '@src/constants/appMessageCmd';

const NEEDS_SIGN_IN = ['/home', '/profile', '/withdraw', '/sign-up'] as const;
const ONLY_APP = ['/push-history', '/home', '/story'] as const;
const NOT_CHECK_PAGE = ['/sign-up', '/start'] as const;

const useLoginAuth = (): void => {
  const router = useRouter();
  const [accessToken] = useAccessToken();
  const [userInfo] = useUserInfoStore();
  const [deviceInfo] = useDeviceStore();
  const [moveToRouter, setRouter] = useAppEventRouter();
  const path = usePathname();

  /**
   * 로그인 여부 확인 및 리다이렉트 처리
   */
  const redirectToLogin = useCallback(
    (url: string) => {
      const redirectUri = btoa(encodeURIComponent(url));
      postAppStatusTextColor('/login');
      router.push(`/login?redirectUri=${redirectUri}`);
    },
    [router],
  );

  /**
   * 회원가입 단계별 처리
   */
  const handleSignUpSteps = useCallback(
    (userData: UserInfoType) => {
      if (agreementValidation(userData)) {
        router.push('/sign-up/step1');
      } else if (nicknameValidation(userData)) {
        router.push('/sign-up/step2');
      } else if (phoneNumberValidation(userData)) {
        router.push('/sign-up/certificate');
      }
    },
    [router],
  );

  /**
   * 경로 변경 처리
   */
  const handleRouteChange = useCallback(
    (url: string) => {
      const basePath = url.split('?')[0];
      const isNeedSignIn = NEEDS_SIGN_IN.some((page) =>
        basePath.startsWith(page),
      );
      const isPass = NOT_CHECK_PAGE.some((page) => basePath.startsWith(page));

      postAppMessage({ cmd: CMD.NOTIFY_URL_CHANGE, path: url });
      postAppStatusTextColor(url as RoutePath);

      if (!isPass) {
        if (userInfo?.userId) {
          handleSignUpSteps(userInfo);
        } else if (isNeedSignIn && !accessToken) {
          redirectToLogin(url);
        }
      }
    },
    [userInfo, accessToken, handleSignUpSteps, redirectToLogin],
  );

  /**
   * 초기 경로 처리
   */
  useEffect(() => {
    handleRouteChange(router.asPath);
  }, [router.asPath, handleRouteChange]);

  /**
   * 라우터 이벤트 처리
   */
  useEffect(() => {
    const handleRouteChangeStart = (url: string) => handleRouteChange(url);
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router, handleRouteChange]);

  /**
   * 앱 전용 페이지 처리
   */
  useEffect(() => {
    if (!deviceInfo?.deviceId) {
      const isBack = ONLY_APP.some((page) => path.startsWith(page));
      if (isBack) router.back();
    }
  }, [path, deviceInfo?.deviceId, router]);

  const handleRoutePush = () => {
    if (router.asPath === moveToRouter) {
      setRouter('');
    } else if (!deviceInfo?.deviceId) {
      router.push(moveToRouter);
      setRouter('');
    } else if (
      !router.asPath.includes('/login') &&
      accessToken &&
      userInfo?.userId
    ) {
      router.push(moveToRouter);
      setRouter('');
    } else if (moveToRouter.includes('/login')) {
      router.push(moveToRouter);
      setRouter('');
    }
  };

  /**
   * 이동 요청 처리
   */
  useEffect(() => {
    if (router.asPath.includes('login')) return;
    if (moveToRouter) {
      handleRoutePush();
    }
  }, [moveToRouter, router.asPath, accessToken, userInfo?.userId]);
};

export default useLoginAuth;
