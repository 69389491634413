import { tagManager } from '@src/tags/utils/tagManager';

export const realTimeRankingTags = {
  RealTimeRanking_PhotoCard_Clicked: () => {
    // 실시간랭킹_포토카드_클릭
    tagManager('RealTimeRanking_PhotoCard_Clicked');
  },
  RealTimeRanking_ApplyBanner_Clicked: () => {
    // 실시간랭킹_참가배너_클릭
    tagManager('RealTimeRanking_ApplyBanner_Clicked');
  },
  RealTimeRanking_GamePlay_Clicked: () => {
    // 실시간랭킹_게임하기_클릭
    tagManager('RealTimeRanking_GamePlay_Clicked');
  },
};
