const GAME_ESSENTIAL_VERSION = [1, 7, 6];

export const gameUpdateOverCheck = (version: string | undefined): boolean => {
  if (!version) return false;
  const [major, minor, build] = version.split('.').map(Number);

  if (major > GAME_ESSENTIAL_VERSION[0]) return true;
  if (major < GAME_ESSENTIAL_VERSION[0]) return false;

  if (major === GAME_ESSENTIAL_VERSION[0]) {
    if (minor > GAME_ESSENTIAL_VERSION[1]) return true;
    if (minor < GAME_ESSENTIAL_VERSION[1]) return false;

    if (minor === GAME_ESSENTIAL_VERSION[1]) {
      if (build > GAME_ESSENTIAL_VERSION[2]) return true;
      if (build < GAME_ESSENTIAL_VERSION[2]) return false;
    }
  }

  return true;
};

export const updateOverCheck = (
  currentVersion: string | undefined,
  requiredVersion: string,
): boolean => {
  if (!currentVersion) return false;

  const [currentMajor, currentMinor, currentBuild] = currentVersion
    .split('.')
    .map(Number);
  const [requiredMajor, requiredMinor, requiredBuild] = requiredVersion
    .split('.')
    .map(Number);

  if (currentMajor > requiredMajor) return true;
  if (currentMajor < requiredMajor) return false;

  if (currentMajor === requiredMajor) {
    if (currentMinor > requiredMinor) return true;
    if (currentMinor < requiredMinor) return false;

    if (currentMinor === requiredMinor) {
      if (currentBuild > requiredBuild) return true;
      if (currentBuild < requiredBuild) return false;
    }
  }

  return true;
};
