import {
  AllHTMLAttributes,
  CSSProperties,
  forwardRef,
  ReactNode,
  Ref,
} from 'react';
import classNames from 'classnames';
import { themeV2 } from '@src/styles/theme_v2';
import {
  Color,
  FontFamily,
  getColorValue,
  getFontValue,
  Typography,
} from '@styles/styleUtils';

interface Props {
  children?: ReactNode;
  className?: string;
  typo?: Typography;
  fontFamily?: FontFamily;
  color?: Color;
  textAlign?: CSSProperties['textAlign'];
  underLine?: boolean;
}

type TextProps<Element extends keyof JSX.IntrinsicElements = 'span'> = Props & {
  as?: Element;
} & Omit<AllHTMLAttributes<Element>, 'as'>;

const Text = <Element extends keyof JSX.IntrinsicElements = 'span'>(
  props: TextProps<Element>,
  ref: Ref<HTMLElement>,
) => {
  const {
    as: Component = 'span',
    children,
    className,
    typo = 'body1',
    fontFamily = 'pretendard',
    color,
    role,
    style,
    textAlign,
    underLine = false,
    ...rest
  } = props as TextProps;

  const colorValue = color ? getColorValue(color) : 'inherit';
  const fontValue = getFontValue(typo);

  return (
    // @ts-expect-error text component props
    <Component
      ref={ref}
      role={role ?? (Component === 'span' ? 'text' : undefined)}
      className={classNames('text', className, {
        underline: underLine,
      })}
      style={{
        color: colorValue,
        ...fontValue,
        ...themeV2.fontFamilyV2[fontFamily],
        textAlign,
        ...style,
      }}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default forwardRef(Text);
