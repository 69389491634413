import styled, { css } from 'styled-components';
import { getColorValue, getFontValue } from '@styles/styleUtils';
import { CSSProperties } from 'react';

interface ModalWrapProps {
  $style: 'white' | 'black';
  $backgroundImage?: string;
  $padding?: string;
}

export const BackgroundWrap = styled.div<{ $blackBackdrop: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ $blackBackdrop }) =>
    $blackBackdrop ? 'black' : 'rgba(18, 18, 20, 0.6)'};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

export const ModalWrap = styled.div<ModalWrapProps>`
  position: relative;
  width: clamp(320px, calc(100% - 40px), 480px);
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  background-color: ${({ $style, $backgroundImage }) =>
    $backgroundImage
      ? 'transparent'
      : $style === 'black'
      ? getColorValue('gray80')
      : getColorValue('white')};
  color: ${({ $backgroundImage }) =>
    $backgroundImage ? getColorValue('white') : getColorValue('gray80')};
  border-radius: 20px;
  padding: ${({ $padding }) => $padding || '40px 16px 28px'};
  align-items: center;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  ${({ $backgroundImage }) =>
    $backgroundImage &&
    css`
      background-image: url('${$backgroundImage}');
      background-size: cover;
    `}
`;

export const ModalHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const ModalTitleWrap = styled.h2<{ $style: 'white' | 'black' }>`
  ${getFontValue('heading2_semiBold')};
  color: ${({ $style }) =>
    $style === 'black' ? getColorValue('white') : getColorValue('gray100')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: pre-line;
  text-align: center;

  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
`;

export const ModalContentWrap = styled.div<{ $style: 'white' | 'black' }>`
  width: 100%;
  flex: 1;

  &.text {
    ${getFontValue('body3')};
    color: ${({ $style }) =>
      $style === 'black' ? getColorValue('gray30') : getColorValue('gray60')};
    text-align: center;
  }
`;

export const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  margin-top: 24px;
`;

export const ModalButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${getFontValue('body1')};
  border-radius: 12px;
  cursor: pointer;
`;

export const SubmitButton = styled(ModalButton)<{ $style?: 'white' | 'black' }>`
  color: ${({ $style }) =>
    $style === 'black' ? getColorValue('gray100') : getColorValue('white')};
  background-color: ${({ $style }) =>
    $style === 'black' ? getColorValue('white') : getColorValue('gray80')};
  padding: 14px 0;
  border: none;
`;

export const CancelButton = styled(ModalButton)<{
  $color: CSSProperties['backgroundColor'];
}>`
  border: 1px solid ${getColorValue('gray30')};
  color: ${({ $color }) =>
    $color ? getColorValue('white') : getColorValue('gray60')};
  padding: 13px 0;
  background-color: ${({ $color }) => $color || getColorValue('white')};
`;
