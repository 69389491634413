import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { getColorValue } from '@styles/styleUtils';
import {
  CouponContentWrap,
  CouponWrap,
  DimmedContentWrap,
} from '@components/common/styles/coupon.style';
import HorizontalCouponSvg from '@components/common/coupon-svg-ui/HorizontalCouponSvg';
import VerticalCouponSvg from '@components/common/coupon-svg-ui/VerticalCouponSvg';

interface Props {
  uniqueKey: string; // svg mask id 지정용
  $borderColor?: string; // 테두리 색상
  $dashColor?: string; // 점선 색상
  $fillColor?: string; // 배경 색상
  $boxShadow?: string; // 그림자
  $style?: CSSProperties;
  horizontalContent?: {
    fixedRightContentWidth?: number;
    left: JSX.Element;
    right: JSX.Element;
  };
  verticalContent?: {
    top: JSX.Element;
    bottom: JSX.Element;
  };
  handleClick?: () => void;
  initialWidth?: number;
  initialHeight?: number;
  dimmedContent?: JSX.Element;
}

/**
 * 정의
 * 1. 가로형 쿠폰: 쿠폰의 영역이 좌우로 나뉘어있는 UI
 *    -> horizontalContent 내부에 left, right 컴포넌트 정의
 *    -> 우측 영역의 width 고정 가능
 * 2. 세로형 쿠폰: 쿠폰의 영역이 위아래로 나뉘어있는 UI
 *    -> verticalContent 내부에 top, bottom 컴포넌트 정의
 * */
const CouponUI = ({
  uniqueKey,
  $borderColor = getColorValue('gray30'),
  $dashColor = getColorValue('gray20'),
  $fillColor = 'white',
  $boxShadow = 'none',
  $style,
  horizontalContent,
  verticalContent,
  handleClick,
  initialWidth,
  initialHeight,
  dimmedContent,
}: Props) => {
  const [svgWidth, setSvgWidth] = useState<number>(initialWidth || 300);
  const [svgHeight, setSvgHeight] = useState<number>(initialHeight || 100);
  const contentRef = useRef<HTMLDivElement | null>(null);

  // 가로형 쿠폰의 오른쪽 영역 너비
  const [rightContentWidth, setRightContentWidth] = useState<number>(
    horizontalContent?.fixedRightContentWidth || 0,
  );
  const rightContentRef = useRef<HTMLDivElement | null>(null);
  const leftWidth = svgWidth - rightContentWidth; // 가로형 쿠폰의 왼쪽 영역 너비

  // 세로형 쿠폰의 위쪽 영역 높이
  const [topContentHeight, setTopContentHeight] = useState<number>(0);
  const topContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // 쿠폰 내부에 들어가는 내용의 높이를 감지하여 svg width & height 계산
    const updateSvgSize = () => {
      if (contentRef.current) {
        setSvgWidth(contentRef.current.offsetWidth);
        setSvgHeight(contentRef.current.offsetHeight);
      }

      if (
        !horizontalContent?.fixedRightContentWidth &&
        rightContentRef.current
      ) {
        // 가로형 쿠폰일 때, 오른쪽 영역의 width 가 고정이 아닌 경우 오른쪽영역의 width 계산
        setRightContentWidth(rightContentRef.current.offsetWidth);
      }

      if (topContentRef.current) {
        // 세로형 쿠폰일 때, 위쪽영역의 height 계산
        setTopContentHeight(topContentRef.current.offsetHeight);
      }
    };

    updateSvgSize();
    window.addEventListener('resize', updateSvgSize);

    return () => window.removeEventListener('resize', updateSvgSize);
  }, [contentRef, horizontalContent, verticalContent]);

  if (verticalContent) {
    return (
      <CouponWrap $style={$style} onClick={handleClick}>
        {/* 세로형 쿠폰 svg */}
        <VerticalCouponSvg
          uniqueKey={uniqueKey}
          $borderColor={dimmedContent ? 'transparent' : $borderColor}
          $dashColor={$dashColor}
          $fillColor={$fillColor}
          $boxShadow={$boxShadow}
          svgWidth={svgWidth}
          svgHeight={svgHeight}
          topContentHeight={topContentHeight}
        />
        {/* 위, 아래 콘텐츠 영역 */}
        <CouponContentWrap direction={'vertical'} ref={contentRef}>
          <div className="top-side" ref={topContentRef}>
            {verticalContent.top}
          </div>
          <div className="bottom-side">{verticalContent.bottom}</div>
        </CouponContentWrap>

        {/* dimmedContent 존재할 경우 z-index: 2로 dimmed 쿠폰 UI와 함께 dimmedContent 표시 */}
        {dimmedContent && (
          <>
            <VerticalCouponSvg
              uniqueKey={uniqueKey + '_dimmed'}
              $borderColor={'transparent'}
              $dashColor={'none'}
              $fillColor={'rgba(0, 0, 0, 0.7)'}
              svgWidth={svgWidth}
              svgHeight={svgHeight}
              topContentHeight={topContentHeight}
            />
            <DimmedContentWrap>{dimmedContent}</DimmedContentWrap>
          </>
        )}
      </CouponWrap>
    );
  }
  return (
    <CouponWrap $style={$style} onClick={handleClick}>
      {/* 가로형 쿠폰 svg */}
      <HorizontalCouponSvg
        uniqueKey={uniqueKey}
        $borderColor={dimmedContent ? 'transparent' : $borderColor}
        $dashColor={$dashColor}
        $fillColor={$fillColor}
        $boxShadow={$boxShadow}
        svgWidth={svgWidth}
        svgHeight={svgHeight}
        leftWidth={leftWidth}
      />
      {/* 좌, 우 콘텐츠 영역 */}
      <CouponContentWrap
        direction={'horizontal'}
        $rightWidth={horizontalContent?.fixedRightContentWidth}
        ref={contentRef}
      >
        <div className="left-side">{horizontalContent?.left}</div>
        <div className="right-side" ref={rightContentRef}>
          {horizontalContent?.right}
        </div>
      </CouponContentWrap>

      {/* dimmedContent 존재할 경우 z-index: 2로 dimmed 쿠폰 UI와 함께 dimmedContent 표시 */}
      {dimmedContent && (
        <>
          <HorizontalCouponSvg
            uniqueKey={uniqueKey + '_dimmed'}
            $borderColor={'transparent'}
            $dashColor={'none'}
            $fillColor={'rgba(0, 0, 0, 0.7)'}
            $isBlur
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            leftWidth={leftWidth}
          />
          <DimmedContentWrap>{dimmedContent}</DimmedContentWrap>
        </>
      )}
    </CouponWrap>
  );
};

export default CouponUI;
