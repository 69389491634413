import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import Image from 'next/image';
import Text from '@components/common/Text';
import gaugeBarImage from '@public/images/4cut-ranking/gauge-bar.png';

const gaugeAnimation = keyframes`
  0% {
    transform: translate(-10px, -50%);
  }

  50% {
    transform: translate(10px, -50%);
  }
    
  100% {
    transform: translate(-10px, -50%);
  }
`;

const Wrap = styled.div<{ center: number }>`
  position: relative;
  width: 100%;
  height: 18px;
  border-radius: 99px;
  overflow: hidden;

  > img {
    position: absolute;
    width: 200vw;
    max-width: calc(480px * 2);
    height: fit-content;
    top: 50%;
    left: ${({ center }) => `calc(${center}% - min(100vw, 480px))`};
    transform: translate(0, -50%);
    animation: ${gaugeAnimation} 1s ease-in-out infinite;
  }
`;

const ScoreWrap = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  padding: 1px 12px 0;
`;

interface Props {
  leftPercent: number;
  rightPercent?: number;
}

const GaugeBar = ({
  leftPercent = 50,
  rightPercent = 100 - leftPercent,
}: Props): JSX.Element => {
  const center = (leftPercent / (leftPercent + rightPercent)) * 100;

  return (
    <Wrap center={center}>
      <Image width={707} height={18} src={gaugeBarImage} alt="" />
      <ScoreWrap>
        <Text
          typo="caption2_bold"
          color="gray100"
          style={{ letterSpacing: '0.6px', fontWeight: '800' }}
        >
          {leftPercent.toFixed(1)}%
        </Text>
        <Text
          typo="caption2_bold"
          color="gray100"
          style={{ letterSpacing: '0.6px', fontWeight: '800' }}
        >
          {rightPercent.toFixed(1)}%
        </Text>
      </ScoreWrap>
    </Wrap>
  );
};

export default GaugeBar;
