import { type GetServerSideProps } from 'next';
import { useEffect } from 'react';
import { dehydrate } from '@tanstack/react-query';
import MainContent from '@features/4cut-ranking-main';
import PushAndSettingIconButtons from '@components/layout/PushAndSettingIconButtons';
import PageLayout from '@components/layout/PageLayout';
import backgroundImage from '@public/images/background/4cut-ranking-main-background.webp';
import useDeviceStore from '@hooks/useDeviceStore';
import { TwoCardsRes } from '@src/apis/querys/main-queries/useGetHotRegionTopTwoCards';
import { HotRegionAndCategoryRes } from '@src/apis/querys/region-queries/useGetHotRegion';
import { Hydrate } from '@src/libs/react-query/react-query';
import { HOT_REGION, REGION_TOP_TWO_CARDS } from '@src/queryKeys';
import gamesApiV2 from '@src/apis/gamesApiV2';
import { DehydratedQueries } from '@src/libs/react-query/types';
import queryClient from '@src/libs/react-query';
import { googleTagManager } from '@src/tags';

interface PageServerProps {
  dehydratedQueries: DehydratedQueries<HotRegionAndCategoryRes | TwoCardsRes>;
}

const Main = ({ dehydratedQueries }: PageServerProps) => {
  const [deviceInfo] = useDeviceStore();
  const isApp = !!deviceInfo?.deviceToken;

  useEffect(() => {
    if (!isApp) {
      googleTagManager.Page_View_Ranking_Web();
    }
  }, [isApp]);

  return (
    <Hydrate state={dehydratedQueries}>
      <PageLayout
        headerColor="transparent"
        mode="dark"
        backgroundImage={backgroundImage}
        titleAlign="left"
        headerRightButton={<PushAndSettingIconButtons />}
      >
        <MainContent />
      </PageLayout>
    </Hydrate>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  const regionData = await queryClient.fetchQuery({
    queryKey: [HOT_REGION],
    queryFn: async () => {
      const { data } = await gamesApiV2.getHotRegion();
      return data;
    },
  });

  await queryClient.prefetchQuery({
    queryKey: [
      REGION_TOP_TWO_CARDS,
      regionData.region,
      regionData.photoCardCategory,
    ],
    queryFn: async () => {
      const { data } = await gamesApiV2.getRegionTopTwoCards({
        region: regionData.region,
        photoCardCategory: regionData.photoCardCategory,
      });
      return data;
    },
  });

  return {
    props: {
      dehydratedQueries: dehydrate(queryClient),
    },
  };
};

export default Main;
