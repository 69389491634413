import { tagManager } from '@src/tags/utils/tagManager';

export const settingTags = {
  Account_Certificate_Clicked: () => {
    // 계정정보_휴대폰번호인증_클릭
    tagManager('Account_Certificate_Clicked');
  },
  Account_Withdraw: (params: { cause: string }) => {
    // 회원탈퇴
    tagManager('Account_Withdraw', params);
  },
  Help_Kakao_Clicked: () => {
    // 고객센터_카카오톡상담하기_클릭
    tagManager('Help_Kakao_Clicked');
  },
  Help_Phone_Clicked: () => {
    // 고객센터_전화하기_클릭
    tagManager('Help_Phone_Clicked');
  },
  Help_Inquiry_Clicked: () => {
    // 고객센터_이용문의_탭_클릭
    tagManager('Help_Inquiry_Clicked');
  },
  Help_Coupon_Clicked: () => {
    // 고객센터_쿠폰/스탬프_탭_클릭
    tagManager('Help_Coupon_Clicked');
  },
  Help_Help_Clicked: () => {
    // 고객센터_고객센터_탭_클릭
    tagManager('Help_Help_Clicked');
  },
};
