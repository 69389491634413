import axiosInstance from '@src/axios';

const postPageViewEvent = async (data: {
  pageId: string;
  eventDateTime: string;
}): Promise<AInstance.DefaultRes | void> => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    return axiosInstance.post('/api/stat/page-view', {
      pageViewEvents: [data],
    });
  }
};
export default {
  postPageViewEvent,
};
