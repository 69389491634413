import { CMD } from '@src/constants/appMessageCmd';

type CmdType = (typeof CMD)[keyof typeof CMD];

interface PostMessage {
  cmd: CmdType;
  shareData?: {
    [key: string]: string | boolean | undefined;
  };
  [key: string]: string | boolean | object | undefined;
}

export const postAppMessage = (data: PostMessage): void => {
  if (typeof window !== 'undefined') {
    window.JavaScriptChannel?.postMessage(JSON.stringify({ ...data }));
  }
};
