import styled from 'styled-components';
import ModalCancel from '@icons/mobile/modal_cancel.svg';
import { getColorValue, getFontValue } from '@styles/styleUtils';

export const DimmedWrap = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: ${({ theme }) => theme.zIndex.modal - 1};

  &.up {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  &.down {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
`;

export const BackgroundWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${getColorValue('black')};
  position: fixed;
  left: 50%;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  transform: translate(-50%, 100%);

  &.up {
    transform: translate(-50%, 0);
    transition: transform 0.3s ease-in-out;
  }

  &.down {
    transform: translate(-50%, 100%);
    transition: transform 0.3s ease-in-out;
  }
`;

export const QRWrap = styled.div`
  padding: 40px;
  border-radius: 12px;
  background-color: ${getColorValue('white')};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const QRBorderWrap = styled.div`
  position: relative;
  padding: 8px;
  margin: 16px 0 20px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 4px;
    background: linear-gradient(to bottom, black, ${getColorValue('red')});
    -webkit-mask: linear-gradient(${getColorValue('white')} 0 0) content-box,
      linear-gradient(${getColorValue('white')} 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  canvas {
    margin: 12px;
  }
`;

export const UniqueCodeWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: ${getColorValue('lightRed')};
  ${getFontValue('body3')};
`;

export const CancelWrap = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  color: white;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
`;

export const CancelButton = styled(ModalCancel)`
  padding: 5px;
  border-radius: 50%;
  background-color: ${getColorValue('gray60')};
  border: 1px solid ${getColorValue('gray40')};
  width: 26px;
  height: 26px;
  margin-right: 8px;
`;
