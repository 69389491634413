import { EventEmitter } from '@utils/storeManager/subscribeListeners';
import { isClient } from '@utils/window';

const EVENT_NAME = 'refreshTokenDataChange';

class RefreshTokenManager extends EventEmitter {
  private static instance: RefreshTokenManager;

  private key = 'refreshToken';

  private constructor() {
    super();

    if (isClient) {
      window.addEventListener(EVENT_NAME, this.handleStorageChange);
    }
  }

  static getInstance(): RefreshTokenManager {
    if (!RefreshTokenManager.instance) {
      RefreshTokenManager.instance = new RefreshTokenManager();
    }
    return RefreshTokenManager.instance;
  }

  getRefreshToken(): string {
    if (isClient) {
      return localStorage.getItem(this.key) || '';
    }
    return '';
  }

  removeRefreshToken(): void {
    if (!isClient) return;

    localStorage.removeItem(this.key);

    window.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: null }));

    this.notify();
  }

  setRefreshToken(newValue: string): void {
    if (isClient) {
      if (!newValue) {
        this.setTokenClear();
        return;
      }

      const currentData = localStorage.getItem(this.key);
      const newData = JSON.stringify(newValue);

      if (currentData === newData) return;

      localStorage.setItem(this.key, newData);
      window.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: newValue }));

      this.notify();
    }
  }

  private setTokenClear() {
    localStorage.removeItem(this.key);
    window.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: '' }));

    this.notify();
  }

  private handleStorageChange = (): void => {
    this.notify();
  };

  destroy(): void {
    super.clearListeners();
    window.removeEventListener(EVENT_NAME, this.handleStorageChange);
  }
}

export default RefreshTokenManager.getInstance();
