import styled, { css } from 'styled-components';
import { StaticImageData } from 'next/image';
import { getColorValue, getFontValue } from '@styles/styleUtils';

export const PageWrap = styled.div<{
  mode?: 'light' | 'dark';
  $backgroundImage?: StaticImageData;
}>`
  ${({ theme }) => theme.layout.width};
  min-height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  background: ${({ mode }) =>
    mode === 'light' ? getColorValue('white') : getColorValue('black')};
  color: ${({ mode }) =>
    mode === 'light' ? getColorValue('gray100') : getColorValue('white')};
  overscroll-behavior: none;
  ${({ $backgroundImage }) =>
    $backgroundImage &&
    css`
      height: 100%;
      background-image: url(${$backgroundImage.src});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `};
  padding-top: ${({ theme }) => theme.safeArea.top};
  padding-bottom: ${({ theme }) => theme.safeArea.bottom};

  &:has(#header) {
    padding-top: ${({ theme }) => theme.headerHeight.computed};
  }

  &:has(#bottom-nav) {
    padding-bottom: ${({ theme }) => theme.webBottomNavHeight.px};
  }

  &:has(#mobile-bottom-nav) {
    padding-bottom: ${({ theme }) => theme.mobileBottomNavHeight.computed};
  }
`;

export const TermsPageWrap = styled.div`
  ${getFontValue('body3')};
  line-height: 22px; // 가독성을 위해 추가
  display: flex;
  flex-direction: column;
  padding: 20px;

  td {
    border: 1px solid black;
    padding: 4px;
    vertical-align: middle;
  }

  thead td {
    background: ${getColorValue('gray10')};
  }

  p {
    word-break: break-all;
  }

  strong {
    font-weight: 600;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
`;
