interface Data {
  cb: () => void;
  once: boolean;
}

export const ON_RESUME_HANDLER_KEY = {
  SPACE_GAME_HOME: 'SPACE_GAME_HOME',
  SPACE_GAME_PLAY: 'SPACE_GAME_PLAY',
} as const;

type ValueOf<T> = T[keyof T];
type Key = ValueOf<typeof ON_RESUME_HANDLER_KEY>;

export class OnResumeHandler {
  static instance: OnResumeHandler;

  private callbacks: Map<Key, Data> = new Map();

  static getInstance(): OnResumeHandler {
    if (!this.instance) {
      this.instance = new OnResumeHandler();
    }

    return this.instance;
  }

  setCallback(key: Key, data: Data): void {
    this.callbacks.set(key, data);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getCallback(key: Key) {
    const { cb, once } = this.callbacks.get(key) ?? {};

    if (once) {
      this.callbacks.delete(key);
    }

    return cb;
  }

  deleteCallback(key: Key): void {
    this.callbacks.delete(key);
  }

  runCallbacks(): void {
    // @ts-ignore
    [...this.callbacks.keys()].forEach((key) => {
      const cb = this.getCallback(key);
      cb?.();
    });
  }
}
