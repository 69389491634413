import { useSyncExternalStore } from 'react';
import AppEventManager from '@utils/storeManager/AppEventManager';

const useAppEventRouter = () => {
  const store = useSyncExternalStore(
    (listener) => AppEventManager.subscribe(listener),
    () => AppEventManager.getAppEvent(),
    () => AppEventManager.getAppEvent(),
  );

  const setAppEvent = (path: string) => AppEventManager.setAppEvent(path);

  return [store, setAppEvent] as const;
};

export default useAppEventRouter;
