import * as React from 'react';
import Image from 'next/image';
import { validate as uuidValidate } from 'uuid';
import styled, { css } from 'styled-components';
import { Ref } from 'react';
import { Assets } from '@src/svg';
import Logo from '@icons/logo/card-logo.svg';
import Text from '@components/common/Text';
import dummyPhotoCardImage from '@public/images/4cut-ranking/dummy-photo-card.webp';

type PhotoCardSizeType = 'XS' | 'S' | 'M' | 'L';

const photoCardSizeStyle = {
  XS: css`
    width: 35vw;
    max-width: 131px;
    padding: 4px 4px 5px;
    border-radius: 12px 12px 16px 16px;

    .logo {
      height: 7px;
      margin-top: 7px;
    }

    .image-wrap {
      position: relative;
      width: calc(35vw - 8px);
      max-width: 123px;
      height: calc((35vw - 8px) * 1.5);
      max-height: calc(123px * 1.5);

      img {
        border-radius: 10px 10px 14px 14px;
        object-fit: cover;
      }
    }
  `,
  S: css`
    width: calc(50vw - 26px);
    max-width: calc(240px - 26px);
    padding: 4px;
    border-radius: 12px 12px 16px 16px;

    .logo {
      height: 9px;
      margin-top: 7px;
    }

    .image-wrap {
      position: relative;
      width: calc(50vw - 34px);
      max-width: calc(240px - 34px);
      height: calc((50vw - 34px) * 1.5);
      max-height: calc((240px - 34px) * 1.5);

      img {
        border-radius: 10px 10px 14px 14px;
        object-fit: cover;
      }
    }
  `,
  M: css`
    width: 58vw;
    max-width: 216px;
    padding: 6px;
    border-radius: 16px 16px 20px 20px;

    .logo {
      height: 14px;
      margin-top: 11px;
    }

    .image-wrap {
      position: relative;
      width: calc(58vw - 12px);
      max-width: 204px;
      height: calc((58vw - 12px) * 1.5);
      max-height: calc(204px * 1.5);

      img {
        border-radius: 13px 13px 18px 18px;
        object-fit: cover;
      }
    }
  `,
  L: css`
    width: calc(100vw - 126px);
    max-width: 240px;
    padding: 6px;
    border-radius: 16px 16px 20px 20px;

    .logo {
      height: 14px;
      margin-top: 11px;
    }

    .image-wrap {
      position: relative;
      width: calc(100vw - 138px);
      max-width: calc(240px - 12px);
      height: calc((100vw - 138px) * 1.5);
      max-height: calc((240px - 12px) * 1.5);

      img {
        border-radius: 13px 13px 18px 18px;
        object-fit: cover;
      }
    }
  `,
};

const PhotoCardWrap = styled.div<{
  size: PhotoCardSizeType;
}>`
  background: linear-gradient(
    135.79deg,
    #cce8fe 4.21%,
    #cda0ff 27.68%,
    #8489f5 43.08%,
    #cdf1ff 73.52%,
    #b591e9 101.38%
  );
  box-shadow: 0 3px 3px 1px rgba(255, 255, 255, 0.6) inset;
  position: relative;
  text-align: center;
  ${({ size }) => photoCardSizeStyle[size]};
  height: fit-content;
  z-index: ${({ theme }) => theme.zIndex.photoCard.wrap};
`;

const DimmedWrap = styled.div`
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border-radius: inherit;
  background: #000000b2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  backdrop-filter: blur(2px);
  z-index: ${({ theme }) => theme.zIndex.photoCard.dimmed};
`;

interface Props {
  photoCardInfo?: Partial<PhotoCardType>;
  size: PhotoCardSizeType;
  handleClick?: () => void;
  showBottomLogo?: boolean;
  dummy?: boolean;
  dimmed?: boolean;
  lazy?: boolean;
  photoRef?: Ref<HTMLDivElement>;
}

const DimmedOverlap = ({
  size,
  status,
  statusCause,
}: {
  size: PhotoCardSizeType;
  status: string;
  statusCause?: string;
}) => {
  return (
    <DimmedWrap>
      {(status === 'APPLIED' ||
        status === 'REPORTED' ||
        status === 'REJECTED') && <Assets.CardLock width={29} height={28} />}
      {size !== 'XS' && (status === 'APPLIED' || status === 'REPORTED') && (
        <Text
          typo={size === 'S' ? 'body3_semiBold' : 'heading2_semiBold'}
          color="white"
        >
          포토카드 심사중
        </Text>
      )}
      {size !== 'XS' && status === 'REJECTED' && (
        <>
          <Text
            typo={size === 'S' ? 'body3_semiBold' : 'heading2_semiBold'}
            color="white"
          >
            참가 제한
          </Text>
          {size === 'L' && (
            <Text typo="caption2" color="white">
              {statusCause}
            </Text>
          )}
        </>
      )}
    </DimmedWrap>
  );
};

const PhotoCard: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  photoCardInfo,
  size,
  handleClick,
  showBottomLogo = true,
  dummy = false,
  dimmed = false,
  lazy = true,
  photoRef,
  ...rest
}) => {
  const isDimmed =
    dimmed ||
    photoCardInfo?.status === 'APPLIED' ||
    photoCardInfo?.status === 'REPORTED' ||
    photoCardInfo?.status === 'REJECTED';

  return (
    <PhotoCardWrap
      ref={photoRef}
      data-testid="photo-card"
      size={size}
      onClick={() => {
        if (
          photoCardInfo &&
          photoCardInfo.photoCardId &&
          uuidValidate(photoCardInfo.photoCardId) &&
          handleClick
        ) {
          handleClick();
        }
      }}
      {...rest}
    >
      <div className={`size_${size} image-wrap`}>
        {dummy && (
          <Image
            id="photo-card"
            src={dummyPhotoCardImage}
            alt=""
            fill
            priority={!lazy}
            sizes="300px"
            loading={lazy ? 'lazy' : 'eager'}
          />
        )}
        {!dummy && photoCardInfo?.thumbnailPhotoUrl && (
          <Image
            id="photo-card"
            src={photoCardInfo.thumbnailPhotoUrl}
            alt={photoCardInfo.thumbnailPhotoUrl}
            fill
            priority={!lazy}
            sizes="300px"
            loading={lazy ? 'lazy' : 'eager'}
          />
        )}
      </div>
      {showBottomLogo && <Logo className="logo" />}

      {isDimmed && (
        <DimmedOverlap
          size={size}
          status={photoCardInfo?.status as PhotoCardStatusType}
          statusCause={photoCardInfo?.statusCause || undefined}
        />
      )}
    </PhotoCardWrap>
  );
};

export default PhotoCard;
