import { tagManager } from '@src/tags/utils/tagManager';

export const applyTags = {
  Apply_Category_Submit: (params: { category: CategoryType }) => {
    // 포토카드등록중_성별_입력
    tagManager('Apply_Category_Submit', params);
  },
  ApplyCompleted_Submit: (params: { category: string; memberType: string }) => {
    // 포토카드등록완료
    tagManager('ApplyCompleted_Submit', params);
  },
  ApplyCompleted_Submit_Web: () => {
    // 포토카드등록완료(웹)
    tagManager('ApplyCompleted_Submit_Web');
  },
  ApplyCompleted_Share_Clicked: () => {
    // 포토카드등록완료_공유하기_클릭
    tagManager('ApplyCompleted_Share_Clicked');
  },
  ApplyCompleted_GamePlay_Clicked: () => {
    // 포토카드등록완료_게임하기_클릭
    tagManager('ApplyCompleted_GamePlay_Clicked');
  },
  ApplyCompleted_Ranking_Clicked: () => {
    // 포토카드등록완료_랭킹보기_클릭
    tagManager('ApplyCompleted_Ranking_Clicked');
  },
  ApplyCompleted_Push_BottomSheet_View: () => {
    // 참가완료_알림허용바텀시트_조회
    tagManager('ApplyCompleted_Push_BottomSheet_View');
  },
  ApplyCompleted_Push_BottomSheet_Submit: () => {
    // 참가완료_알림허용바텀시트_동의_클릭
    tagManager('ApplyCompleted_Push_BottomSheet_Submit');
  },
  ApplyCompleted_Push_BottomSheet_Exit: () => {
    // 참가완료_알림허용바텀시트_이탈
    tagManager('ApplyCompleted_Push_BottomSheet_Exit');
  },
};
