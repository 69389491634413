import styled from 'styled-components';
import { getFontValue } from '@styles/styleUtils';
import { getRegionName } from '@src/constants/region';

const Wrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 284px;
`;

const HorizontalLine = styled.span`
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 20%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.6) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
`;

const RegionWrap = styled.div`
  position: relative;
  z-index: 2;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(106.99deg, #312c3c 17.68%, #120e19 90.67%);
    border: 1px solid #fff;
    color: #fff;
    ${getFontValue('body3')};
    ${({ theme }) => theme.fontFamilyV2.establish};
    padding: 0 19px;
    height: 29px;
    border-radius: 99px;
    z-index: 2;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    left: -4px;
  }

  &::after {
    right: -4px;
  }
`;

interface Props {
  region?: RankingRegionType;
}

const TrendingRegion = ({ region = null }: Props): JSX.Element => {
  return (
    <Wrap>
      <RegionWrap>
        <span>{getRegionName(region)}</span>
      </RegionWrap>
      <HorizontalLine />
    </Wrap>
  );
};

export default TrendingRegion;
