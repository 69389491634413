import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface ApplyFormType {
  region: RankingRegionType | '';
  photoCardCategory: CategoryType | '';
  photoCardName: string;
  countryNumber: '+82';
  phoneNumber: string;
  originPhotoPath: string;
  originPhoto: File | undefined;
  resizedOriginPhoto: File | undefined;
  thumbnailPhotoPath: string;
  thumbnailPhoto: string | undefined;
  acceptedBy: 'NONE' | 'OCR' | 'EXIF';
}

export const initialState: ApplyFormType = {
  region: '',
  photoCardCategory: '',
  photoCardName: '',
  countryNumber: '+82',
  phoneNumber: '',
  originPhotoPath: '',
  thumbnailPhotoPath: '',
  acceptedBy: 'NONE',
  originPhoto: undefined,
  resizedOriginPhoto: undefined,
  thumbnailPhoto: undefined,
};

interface Return {
  data: ApplyFormType;
  updateData: (partialData: Partial<ApplyFormType>) => void;
  resetAndUpdateData: (partialData: Partial<ApplyFormType>) => void;
  resetData: () => void;
}

export const useApplyFormStore = create<Return>((set) => ({
  data: initialState,
  updateData: (partialData: Partial<ApplyFormType>) =>
    set((prev: { data: ApplyFormType }) => ({
      data: {
        ...prev.data,
        ...partialData,
      },
    })),
  resetAndUpdateData: (partialData: Partial<ApplyFormType>) =>
    set({
      data: {
        ...initialState,
        ...partialData,
      },
    }),
  resetData: () =>
    set({
      data: initialState,
    }),
}));

export const useNeverShowApplyGuideStore = create(
  persist<{
    neverShowApplyGuide: boolean;
    setNeverShowApplyGuide: () => void;
  }>(
    (set) => ({
      neverShowApplyGuide: false,
      setNeverShowApplyGuide: () =>
        set({
          neverShowApplyGuide: true,
        }),
    }),
    {
      name: 'neverShowApplyGuide',
    },
  ),
);
