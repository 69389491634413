import { EventEmitter } from '@utils/storeManager/subscribeListeners';
import { isClient } from '@utils/window';

const EVENT_NAME = 'deviceDataChange';

class DeviceDataManager extends EventEmitter {
  private static instance: DeviceDataManager;

  private key = 'deviceData';

  private cachedData: MobileDataState | null = null;

  private constructor() {
    super();

    if (isClient) {
      window.addEventListener(EVENT_NAME, this.handleStorageChange);
      this.cachedData = this.loadFromLocalStorage();
    }
  }

  static getInstance(): DeviceDataManager {
    if (!DeviceDataManager.instance) {
      DeviceDataManager.instance = new DeviceDataManager();
    }
    return DeviceDataManager.instance;
  }

  private loadFromLocalStorage(): MobileDataState | null {
    const data = localStorage.getItem(this.key);
    return data ? (JSON.parse(data) as MobileDataState) : null;
  }

  getDeviceData(): MobileDataState | null {
    return this.cachedData;
  }

  removeInfo(): void {
    if (!isClient) return;

    this.cachedData = null;
    localStorage.removeItem(this.key);

    window.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: null }));

    this.notify();
  }

  setDeviceData(newValue: MobileDataState): void {
    if (!isClient) return;

    const currentData = JSON.stringify(this.cachedData);
    const newData = JSON.stringify(newValue);

    if (currentData === newData) return;

    this.cachedData = newValue;
    localStorage.setItem(this.key, newData);
    window.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: newValue }));
    this.notify();
  }

  private handleStorageChange = (): void => {
    this.cachedData = this.loadFromLocalStorage();
    this.notify();
  };

  destroy(): void {
    super.clearListeners();
    window.removeEventListener(EVENT_NAME, this.handleStorageChange);
  }
}

export default DeviceDataManager.getInstance();
