import styled, { keyframes } from 'styled-components';
import { Assets } from '@src/svg';
import Text from '@components/common/Text';
import { getColorValue } from '@styles/styleUtils';

const bounceAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
    
  50% {
    transform: translateY(-4px);
  }
    
  100% {
    transform: translateY(0);
  }
`;

const Wrap = styled.div`
  position: absolute;
  top: 0;
  right: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: ${getColorValue('primary.purple')};
  padding: 5px 12px;
  border-radius: 99px;
  animation: ${bounceAnimation} 1s ease infinite;

  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 75%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${getColorValue('primary.purple')} transparent transparent
      transparent;
  }
`;

interface Props {
  count?: number;
}

const PlayingUserTooltip = ({ count = 0 }: Props): JSX.Element => {
  return (
    <Wrap>
      <Assets.UserGroup width={18} height={18} />
      <Text as="p" typo="body3" color="white">
        <Text typo="body3_semiBold" color="white">
          {count.toLocaleString()}
        </Text>
        명 참여중
      </Text>
    </Wrap>
  );
};

export default PlayingUserTooltip;
