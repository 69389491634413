import { EventEmitter } from '@utils/storeManager/subscribeListeners';
import { isClient } from '@utils/window';

const EVENT_NAME = 'userInfoChange';

class UserInfoManager extends EventEmitter {
  private static instance: UserInfoManager;

  private key = 'userInfo';

  private cachedData: UserInfoType | null = null;

  private constructor() {
    super();

    if (isClient) {
      window.addEventListener(EVENT_NAME, this.handleStorageChange);
      this.cachedData = this.loadFromLocalStorage();
    }
  }

  static getInstance(): UserInfoManager {
    if (!UserInfoManager.instance) {
      UserInfoManager.instance = new UserInfoManager();
    }
    return UserInfoManager.instance;
  }

  private loadFromLocalStorage(): UserInfoType | null {
    const data = localStorage.getItem(this.key);
    return data ? JSON.parse(data) : null;
  }

  getUserInfo(): UserInfoType | null {
    return this.cachedData;
  }

  setUserInfo(newValue: UserInfoType | null): void {
    if (!isClient) return;

    if (!newValue) {
      this.setClearUserInfo();
      return;
    }

    const currentData = JSON.stringify(this.cachedData);
    const newData = JSON.stringify(newValue);

    if (currentData === newData) return;

    this.cachedData = newValue;
    localStorage.setItem(this.key, newData);
    window.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: newValue }));
    this.notify();
  }

  private setClearUserInfo() {
    localStorage.removeItem(this.key);

    window.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: '' }));
    this.notify();
  }

  private handleStorageChange = (): void => {
    this.cachedData = this.loadFromLocalStorage();
    this.notify();
  };

  destroy(): void {
    super.clearListeners();
    window.removeEventListener(EVENT_NAME, this.handleStorageChange);
  }
}

export default UserInfoManager.getInstance();
